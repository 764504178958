export const UNEXPECTED_ERROR = "An unexpected error occurred. Please try again."
export const MAGIC_LINK_SUCCESS = `If that email address is in our database, we will send you an email to login to your account.`
export const FORGOT_PASSWORD_SUCCESS = `If that email address is in our database, we will send you an email to reset your password.`
export const CONFIRM_EMAIL_MESSAGE =
    "You should receive an email with a link to confirm your email address within the next few minutes. If you do not receive an email, make sure to check your spam."
export const FORGOT_PASSWORD_MESSAGE =
    "Enter your email address and we will send you an email with a link that will let you reset your password."
export const PASSWORDLESS_MESSAGE =
    "You can choose between receiving an email to reset your password or receiving an email with a magic link that will log you in."
export const ORG_CREATION_NOT_ENABLED = "Organization creation not enabled."
export const LOGIN_PASSWORDLESS_NOT_SUPPORTED = "Passwordless login not supported."
export const NO_ACCOUNT_FOUND_WITH_CREDENTIALS = "No account found with those credentials."
export const SIGNUP_NOT_ALLOWED = "Signups are disabled."
export const X_CSRF_TOKEN = "-.-"
export const BAD_REQUEST = "Invalid request"
export const PASSWORDLESS_LOGIN_SUBMITTED_PUBLIC = `We sent you an email with a link that will sign you in. The link will expire in one hour, so be sure to use it soon. You can close this tab.`
export const PASSWORDLESS_LOGIN_SUBMITTED = `If an account already exists with that email address, we will send you a link to sign you in. The link will expire in one hour, so be sure to use it soon. You can close this tab.`
export const RESEND_CONFIRM_EMAIL_MESSAGE = "Confirmation email sent successfully."
export const COMPLETE_ACCOUNT_TEXT = "We need a little more information to complete your account."
export const ORG_NAME_NOT_FOUND = "Name not found."
export const RATE_LIMIT_EMAIL_CONFIRMATION = "Please wait, it may take a few minutes to receive your confirmation email"
export const INCORRECT_PASSWORD = "Incorrect password"
export const ORGS_NOT_ENABLED = "Organizations are not enabled."
export const INCORRECT_MFA_CODE = "Incorrect code"
export const MFA_TIMED_OUT = "Code expired"
export const NO_PASSWORDLESS_URL = `Passwordless login is enabled for your project. The LoginPasswordless component is used to handle those cases. Please implement onRedirectToPasswordlessLogin and have it redirect to the page with a LoginPasswordless component.`
export const NO_SSO_URL = `Login with SSO is enabled for your project. The LoginSSO component is used to handle those cases. Please implement onRedirectToSSOLogin and have it redirect to the page with a LoginSSO component.`
