import { useTranslation } from "react-i18next";
import FloatingIndicator from "components/FloatingIndicator";
import LoadingLogo from "assets/logo_loader.gif";
import { Content } from "./FileUploadingIndicator.styled";

type FileUploadingIndicatorProps = {
  openIndicatorState: [boolean, (value: boolean) => void];
};

const FileUploadingIndicator = ({
  openIndicatorState: [openFileUploadingIndicator, setOpenFileUploadingIndicator],
}: FileUploadingIndicatorProps) => {
  const { t } = useTranslation();

  const onClose = () => {
    setOpenFileUploadingIndicator(false);
  };

  return (
    <FloatingIndicator visible={openFileUploadingIndicator} onClose={onClose}>
      <Content>
        <span>{t("Uploading your files")}...</span>
        <img loading="lazy" src={LoadingLogo} alt="Loading" />
        <p>
          {t(
            `Please be patient, this may take a few minutes.
            We will send you an email once the process is complete.
            You may close this message.`,
          )}
        </p>
      </Content>
    </FloatingIndicator>
  );
};

export default FileUploadingIndicator;
