import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDocumentTitle } from "hooks/useDocumentTitle";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useInfiniteHits, useInstantSearch, useToggleRefinement } from "react-instantsearch";
import { withAuthInfo, WithLoggedInAuthInfoProps } from "@propelauth/react";
import ActionButtons from "features/Tracking/components/ActionButtons";
import CurrentFilters from "features/Tracking/components/CurrentFilters/CurrentFilters";
import TrackingTable from "features/Tracking/components/TrackingTable";
import FileUploadingIndicator from "features/Tracking/components/FileUploadingIndicator";
import AlgoliaConfigure from "features/Tracking/components/AlgoliaConfigure/AlgoliaConfigure";
import { TripHitProps } from "types/trackingTypes";
import { sanitizeAlgoliaTrips } from "utils/sanitizeAlgoliaTrips";
import { upsertFilter } from "services/api/filters";
import config from "config";
import { getTrackingRecords } from "services/api/tracking";

const { USE_BACKEND_RECORDS } = config.envs;

const AlgoliaTrackingTable = () => {
  const { hits } = useInfiniteHits<TripHitProps>();
  const tripsRecords = sanitizeAlgoliaTrips(hits);

  return <TrackingTable trackingRecords={tripsRecords} />;
};

const BackendTrackingTable = withAuthInfo(({ accessToken }: WithLoggedInAuthInfoProps) => {
  const {
    value: { isRefined: isArchived },
  } = useToggleRefinement({ attribute: "archived" });

  const { data: activeRecords = [] } = useQuery({
    queryFn: () => getTrackingRecords(accessToken),
    queryKey: ["trackingRecords"],
    enabled: !isArchived,
  });

  const { data: archivedRecords = [] } = useQuery({
    queryFn: () => getTrackingRecords(accessToken, true),
    queryKey: ["archivedRecords"],
    enabled: isArchived,
  });

  return <TrackingTable trackingRecords={isArchived ? archivedRecords : activeRecords} />;
});

const Tracking = withAuthInfo(({ accessToken }: WithLoggedInAuthInfoProps) => {
  const [openFileUploadingIndicator, setOpenFileUploadingIndicator] = useState(false);
  const { t } = useTranslation();
  const { uiState } = useInstantSearch();

  useDocumentTitle(t("Tracking"));

  const { mutate: saveUIState } = useMutation({
    mutationFn: () => upsertFilter(accessToken, JSON.stringify(uiState)),
  });

  useEffect(() => {
    saveUIState();
  }, [uiState]);

  return (
    <>
      <AlgoliaConfigure />
      <ActionButtons setOpenLoadingIndicator={setOpenFileUploadingIndicator} />
      <CurrentFilters />
      {USE_BACKEND_RECORDS === "true" ? <BackendTrackingTable /> : <AlgoliaTrackingTable />}
      <FileUploadingIndicator
        openIndicatorState={[openFileUploadingIndicator, setOpenFileUploadingIndicator]}
      />
    </>
  );
});

export default Tracking;
