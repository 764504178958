import { useEffect, useRef, useState } from "react";
import { Drawer, DrawerContent, CloseButton } from "./TrackingDetails.styled";
import CloseIcon from "assets/icons/close_icon.svg";
import WhiteCloseIcon from "assets/icons/white_close_icon.svg";
import PDFIcon from "assets/icons/pdf_icon.svg";
import { useLegStore } from "store/trackingStore";
import { useQuery } from "@tanstack/react-query";
import { getTrackingLegDetails } from "services/api/tracking";
import { WithLoggedInAuthInfoProps, withAuthInfo } from "services/auth";
import { TrackingLegDetails } from "types/trackingTypes";
import { useTranslation } from "react-i18next";
import { statuses, statusTransportIcons } from "features/Tracking/constants/statuses";
import { formatDate } from "utils/date";
import { useFileURL } from "hooks/useFileURL";
import Tooltip from "components/ui/Tooltip";

const DEFAULT_WIDTH = "574px";
const HIDDEN_WIDTH = "0px";
const MAX_FILE_NAME_LENGTH = 30;

const TrackingDetails = ({ accessToken }: WithLoggedInAuthInfoProps) => {
  const [fileId, setFileId] = useState<string>("");
  const [drawerWidth, setDrawerWidth] = useState<string>("0px");
  const [selectedCargoUnitRefId, setSelectedCargoUnitRefId] = useState<string>("");
  const drawerRef = useRef<HTMLDivElement>(null);
  const { legId, setLegId } = useLegStore();
  const [fileURL, setFileURL] = useFileURL(fileId, accessToken);

  const {
    t,
    i18n: { language },
  } = useTranslation();

  const { data: legData = {} as TrackingLegDetails } = useQuery({
    queryFn: () => getTrackingLegDetails(accessToken, legId),
    queryKey: ["getTrackingLegDetails", accessToken, legId],
  });

  const getCargoItems = () => {
    return legData?.details?.cargoUnits?.find(
      (cargoUnit) => cargoUnit.referenceId === selectedCargoUnitRefId,
    )?.items;
  };

  const trimFileName = (fileName: string): string => {
    if (fileName.length <= MAX_FILE_NAME_LENGTH) {
      return fileName;
    } else {
      return fileName.substring(0, MAX_FILE_NAME_LENGTH) + "...";
    }
  };

  const resetState = () => {
    setLegId(null);
    setFileId("");
    setFileURL("");
    setSelectedCargoUnitRefId("");
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (drawerRef.current && !drawerRef.current.contains(event.target as Node)) {
        resetState();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setLegId, setFileId, setFileURL]);

  useEffect(() => {
    if (legId && fileURL !== "") {
      setDrawerWidth("80%");
    } else if (legId && fileURL === "") {
      setDrawerWidth(DEFAULT_WIDTH);
    } else {
      setDrawerWidth(HIDDEN_WIDTH);
    }
  }, [legId, fileURL]);

  const {
    transportStatusHistory = [],
    modeOfTransport = "ocean",
    cargoUnits = [],
  } = legData?.details || {};

  const cargoUnitRefIds = cargoUnits.map((cargoUnit) => cargoUnit.referenceId);

  useEffect(() => {
    if (selectedCargoUnitRefId === "" && cargoUnits.length > 0) {
      // Set the first cargo unit as the selected cargo unit by default
      setSelectedCargoUnitRefId(cargoUnits[0].referenceId);
    }
  }, [legData]);

  return (
    <Drawer ref={drawerRef} width={drawerWidth}>
      <CloseButton onClick={resetState} aria-label="Close">
        <img src={CloseIcon} alt="Close Icon" />
      </CloseButton>
      <DrawerContent data-testid="tracking-details">
        <div className="documents">
          <div className="header">
            <h3>{t("Documents")}</h3>
          </div>
          <table>
            <tbody>
              {legData?.documents?.map((document) => (
                <tr
                  className={document.fileId === fileId ? "active" : ""}
                  key={document.fileId}
                  onClick={() => {
                    setFileId(document.fileId);
                  }}
                >
                  <td>
                    <Tooltip
                      content={document.fileName}
                      enabled={document.fileName.length > MAX_FILE_NAME_LENGTH}
                    >
                      <div className="fileName">
                        <img src={PDFIcon} alt="PDF Icon" />
                        <span>{trimFileName(document.fileName)}</span>
                      </div>
                    </Tooltip>
                  </td>
                  <td className="fileRefId">{document.referenceId}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="lineItemsCard">
          <div className="containers">
            <h4>Container</h4>
            <div className="containersList">
              {cargoUnitRefIds.map((cargoUnitRefId) => (
                <div
                  className={`containerItem ${cargoUnitRefId === selectedCargoUnitRefId ? "active" : ""}`}
                  key={cargoUnitRefId}
                  onClick={() => setSelectedCargoUnitRefId(cargoUnitRefId)}
                >
                  {cargoUnitRefId}
                </div>
              ))}
            </div>
          </div>
          <div className="contentContainer">
            <h4>Content</h4>
            <div className="contentList">
              {getCargoItems()?.map((item) => (
                <div className="contentItem" key={item.itemId}>
                  {item.description}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="leg">
          <h4>{modeOfTransport} Milestone</h4>
          <div className="milestones">
            {transportStatusHistory.map(({ transportStatus, date }) => (
              <div key={transportStatus} className="milestone">
                <img
                  src={
                    transportStatus
                      ? statusTransportIcons[modeOfTransport][statuses[transportStatus]]
                      : ""
                  }
                  alt={transportStatus}
                />
                <p>{formatDate(date, language)}</p>
                <p>{t(transportStatus)}</p>
              </div>
            ))}
          </div>
        </div>
      </DrawerContent>
      {fileId && fileURL === "" && <p>{t("Loading")}...</p>}
      {fileURL !== "" && (
        <div className="fileViewer">
          <iframe src={fileURL} title="file" allowFullScreen>
            <p>{t("The file cannot be displayed in your browser")}</p>
          </iframe>
          <button
            className="close"
            onClick={() => {
              setFileId("");
              setFileURL("");
            }}
            aria-label="Close File"
          >
            <img src={WhiteCloseIcon} alt="Close Icon" />
          </button>
        </div>
      )}
    </Drawer>
  );
};

export default withAuthInfo(TrackingDetails);
