import { useToggleRefinement } from "react-instantsearch";
import { Configure } from "react-instantsearch";

const AlgoliaConfigure = () => {
  // This will toggle the archived filter on and off.
  const { value: ArchivedValue } = useToggleRefinement({ attribute: "archived" });
  return <Configure filters={`archived:${ArchivedValue.isRefined}`} />;
};

export default AlgoliaConfigure;
