import styled from "@emotion/styled";

export const Content = styled.div`
  display: flex;
  height: 100%;
  width: 307px;
  flex-direction: column;

  span:first-of-type {
    font-size: 14px;
    margin-bottom: 40px;
  }

  img {
    width: 307px;
    margin-bottom: 15px;
  }
`;
