import styled from "@emotion/styled";

export const FilterDisplay = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 12px;

  .titleSection {
    padding-top: 5px;
    width: 130px;
  }

  span {
    margin-left: 5px;
  }
`;

export const FilterRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4px 13px;
  margin-bottom: 6px;
  margin-right: 15px;
  padding-right: 10px;
  border-right: 1px solid grey;

  &.noBorder {
    border: none;
  }
`;
