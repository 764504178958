import styled from "@emotion/styled";

export const Container = styled.div<{ visible: boolean }>`
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 20px;
  background-color: #fff;
  border-radius: 22px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
  border: 0.5px solid #ededf1;
  transition:
    opacity 0.3s ease-in-out,
    transform 0.3s ease-in-out;
  opacity: ${(props) => (props.visible ? 1 : 0)};
  /* transform: translateY(${(props) => (props.visible ? 0 : "20px")}); */
  display: ${(props) => (props.visible ? "block" : "none")};
  transform: scale(${(props) => (props.visible ? 1 : 0.9)});
  z-index: 1000;

  button {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background: none;
    cursor: pointer;
  }
`;
