export function calculateDifferenceInDays(date1: Date, date2: Date): number {
  const timeDifference = date2.getTime() - date1.getTime();
  const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  return daysDifference;
}

export function delayInDaysFormat(date1: Date, date2: Date, t: { (key: string): string }): string {
  const numDays = calculateDifferenceInDays(date1, date2);
  return numDays === 1 ? `1 ${t("day")}` : `${numDays} ${t("day")}s`;
}

export function formatDate(date: Date, lang: string): string {
  const dateFormatLangs: { [key: string]: string } = {
    es: "es-ES",
    en: "en-US",
  };
  return new Intl.DateTimeFormat(dateFormatLangs[lang], {
    year: "2-digit",
    month: "2-digit",
    day: "2-digit",
  }).format(date);
}
