import { alertsTopicDisplay } from "./alerts";
import { statuses } from "./statuses";

interface FilterAttribute {
  attribute: string;
  display: string;
}

export const attributesDisplay: Record<string, string> = {
  tripStatus: "Trip Status",
  tripOriginCountry: "Origin Country",
  tripDestinationCountry: "Destination Country",
  vendor: "Vendor",
  alertIssueTopics: "Alerts Topic",
  legOriginCity: "Origin",
  legDestinationCity: "Destination",
  legCarrier: "Carrier",
  legMode: "Mode of Transport",
  division: "Division",
};

export const filterAttributes: Record<"trip" | "leg", FilterAttribute[]> = {
  trip: [
    { attribute: "tripStatus", display: attributesDisplay.tripStatus },
    { attribute: "tripOriginCountry", display: attributesDisplay.tripOriginCountry },
    { attribute: "tripDestinationCountry", display: attributesDisplay.tripDestinationCountry },
    { attribute: "vendor", display: attributesDisplay.vendor },
    { attribute: "alertIssueTopics", display: attributesDisplay.alertIssueTopics },
    { attribute: "division", display: attributesDisplay.division },
  ],
  leg: [
    { attribute: "legOriginCity", display: attributesDisplay.legOriginCity },
    { attribute: "legDestinationCity", display: attributesDisplay.legDestinationCity },
    { attribute: "legCarrier", display: attributesDisplay.legCarrier },
    { attribute: "legMode", display: attributesDisplay.legMode },
  ],
};

export const filterItemsDisplay: Record<string, string> = {
  ...alertsTopicDisplay,
  ...statuses,
};
