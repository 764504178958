import { TrackingRecord, TripHitProps } from "types/trackingTypes";

export function sanitizeAlgoliaTrips(algoliaRecords: TripHitProps[]): TrackingRecord[] {
  const records = [];
  for (const record of algoliaRecords) {
    const { tripData } = record;

    // skip if tripData is undefined
    if (!tripData) {
      continue;
    }

    records.push({
      ...tripData,
      currentEta: tripData.currentEta && new Date(tripData.currentEta),
      originalEta: tripData.originalEta && new Date(tripData.originalEta),
      lastTrackingUpdate: new Date(tripData.lastTrackingUpdate),
      legs: tripData.legs.map((leg) => ({
        ...leg,
        lastTrackingUpdate: new Date(leg.lastTrackingUpdate),
        transportCurrentEta: leg.transportCurrentEta && new Date(leg.transportCurrentEta),
        transportOriginalEta: leg.transportOriginalEta && new Date(leg.transportOriginalEta),
        currentEta: leg.currentEta && new Date(leg.currentEta),
        originalEta: leg.originalEta && new Date(leg.originalEta),
      })),
    });
  }

  return records;
}
