import apiClient from "./apiClient";

export const getMetabaseToken = async (
  accessToken: string,
  dashboardId: number,
): Promise<string> => {
  const res = await apiClient.post<{ metabaseToken: string }>(
    "/analytics/metabase-token",
    { dashboardId },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );

  return res.data.metabaseToken;
};
