import styled from "@emotion/styled";

export const Container = styled.div<{ disabled?: boolean }>`
  height: 80vh;
  overflow-y: auto;
  overflow: ${(props) => (props.disabled ? "hidden" : "overlay")};
  border-top-right-radius: 25.546px;
  border-top-left-radius: 25.546px;
  border: 1.064px solid rgb(233, 233, 238);
  background: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1.06441px 5.32203px 0px;
`;

export const Table = styled.table`
  width: 100%;
  font-size: 14px;
  border-spacing: 0;
`;
