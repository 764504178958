import { Container, FormControl, Grid, InputLabel, MenuItem, Paper, Select } from "@mui/material";
import { useTranslation } from "react-i18next";
import { withRequiredAuthInfo, WithLoggedInAuthInfoProps } from "services/auth";
import { EmailProvider, getIngestAuthorizationRedirectUrl } from "../services/api/ingestion";
import Copyright from "../components/layouts/Copyright";
import { useDocumentTitle } from "hooks/useDocumentTitle";

interface ProviderChoice {
  name: string;
}

type EmailIngestionProps = {
  choices: ProviderChoice[];
} & WithLoggedInAuthInfoProps;

function EmailIngestion({ accessToken, choices }: EmailIngestionProps) {
  const { t } = useTranslation();
  useDocumentTitle(t("Email Ingestion"));

  const handleChoiceSelection = async (choiceName: EmailProvider) => {
    const response = await getIngestAuthorizationRedirectUrl(accessToken, choiceName);
    window.location.href = response.redirectUrl;
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12}>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              height: 600,
            }}
          >
            <div>
              <h2>{t("Please select your email provider")}:</h2>
              <FormControl style={{ minWidth: 150 }}>
                <InputLabel>{t("Provider")}</InputLabel>
                <Select
                  label={t("Provider")}
                  defaultValue={""}
                  onChange={(e) => {
                    handleChoiceSelection(e.target.value as EmailProvider);
                  }}
                >
                  {choices.map((choice) => (
                    <MenuItem key={choice.name} value={choice.name.toLowerCase()}>
                      {choice.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </Paper>
        </Grid>
      </Grid>
      <Copyright />
    </Container>
  );
}

export default withRequiredAuthInfo(EmailIngestion);
