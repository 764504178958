import { useTranslation } from "react-i18next";
import { TripCargoUnit } from "types/trackingTypes";
import { modeOfTransportIcons } from "features/Tracking/constants/tableData";

import ContainerIcon from "assets/icons/v2/container_icon.svg";
import TruckIcon from "assets/icons/truck_icon.svg";
import MilestoneCompleteIcon from "assets/icons/v2/milestone_complete.svg";
import MilestoneNotStartedIcon from "assets/icons/v2/milestone_not_started.svg";
import { CargoUnitDetailsContainer, Milestones } from "./CargoUnitDetails.styled";
import { capitalize } from "utils/string";
import { formatDate } from "utils/date";

type CargoUnitDetailsProps = {
  cargoUnit: TripCargoUnit;
};

const CargoUnitDetails = ({ cargoUnit }: CargoUnitDetailsProps) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  return (
    <CargoUnitDetailsContainer className="cargoUnitContent">
      <div className="label">{t("Container Contents")}</div>
      <div className="lineItems">
        <div className="header">
          <img src={ContainerIcon} alt="CargoUnit Icon" />
          <span>{cargoUnit.referenceId}</span>
        </div>
        {cargoUnit.lineItems?.map((lineItem: { description: string }, i: number) => (
          <div key={i} className="lineItem">
            {lineItem.description}
          </div>
        ))}
      </div>
      <div className="label">{t("Container Status")}</div>
      <div className="modeOfTransport">
        <img
          src={modeOfTransportIcons[cargoUnit.modeOfTransport] ?? TruckIcon}
          alt="Tracking Icon"
        />
        {t(capitalize(cargoUnit.modeOfTransport))}
      </div>
      <div className="label">{t("Milestones")}</div>
      <Milestones>
        {cargoUnit && cargoUnit.milestones.length && (
          <div className="row">
            {cargoUnit.milestones.map((milestone, i) => {
              const hasATA = !!milestone.ata;
              // In case milestone data is not present on the leg,
              // if a future milestone has an ATA value backfill a 'completed' UI milestone
              const futureAta = cargoUnit.milestones.slice(i).some((milestone) => milestone.ata);
              return (
                <div key={i} className="column milestone">
                  <div className={hasATA || futureAta ? "ata" : "eta"}>
                    <div className="item mileStoneStatus">
                      <img
                        src={hasATA || futureAta ? MilestoneCompleteIcon : MilestoneNotStartedIcon}
                      />
                    </div>
                    {milestone.ata || milestone.eta ? (
                      <div className="item">
                        {milestone.ata
                          ? `ATA: ${formatDate(new Date(milestone.ata), language)}`
                          : `ETA: ${milestone.eta ? formatDate(new Date(milestone.eta), language) : "N/A"}`}
                      </div>
                    ) : (
                      <div className="item">{futureAta ? "ATA: N/A" : "ETA: N/A"}</div>
                    )}
                    <div className="item">{t(milestone.name)}</div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </Milestones>
    </CargoUnitDetailsContainer>
  );
};

export default CargoUnitDetails;
