import React from "react";
import { useTranslation } from "react-i18next";
import { useSortBy } from "react-instantsearch";
import { Header } from "./TableHeader.styled";
import ArrowRightIcon from "assets/icons/arrow_right_light_icon.svg";
import config from "config";
import { tableHeaders } from "features/Tracking/constants/tableData";

const AlgoliaSortableHeader: React.FC<{ label: string; algoliaKey: string; sortKey: string }> = ({
  label,
  algoliaKey,
  sortKey,
}) => {
  const { ALGOLIA_INDEX_NAME } = config.envs;
  const { t } = useTranslation();
  const { options, currentRefinement, refine } = useSortBy({
    items: [
      { value: `${algoliaKey}_desc`, label: "desc" },
      { value: `${algoliaKey}_asc`, label: "asc" },
      { value: `${ALGOLIA_INDEX_NAME}`, label: "default" },
    ],
  });

  const handleSortClick = () => {
    const currentIndex = options.findIndex((item) => item.value === currentRefinement);
    const nextIndex = (currentIndex + 1) % options.length;
    refine(options[nextIndex].value);
  };

  const getSortIconClass = () => {
    return options.find((item) => item.value === currentRefinement)?.label || "default";
  };

  return (
    <th className={`sortable ${sortKey}`} onClick={handleSortClick}>
      <div className="headerContent">
        <div className="headerText">
          {label.split(" ").map((word) => (
            <span key={word}>{t(word)} </span>
          ))}
        </div>
        <img src={ArrowRightIcon} alt="sort icon" className={`sortIcon ${getSortIconClass()}`} />
      </div>
    </th>
  );
};

const TableHeader = () => {
  const { t } = useTranslation();

  return (
    <Header>
      <tr>
        {tableHeaders.map(({ label, sortable, sortKey, algoliaKey }) => {
          const translatedLabel = t(label);

          if (sortable && algoliaKey) {
            return (
              <AlgoliaSortableHeader
                key={label}
                label={translatedLabel}
                algoliaKey={algoliaKey}
                sortKey={sortKey}
              />
            );
          }

          return (
            <th className={sortKey || ""} key={label}>
              <div className="headerContent">
                <div className="headerText">
                  {translatedLabel.split(" ").map((word) => (
                    <span key={word}>{t(word)} </span>
                  ))}
                </div>
              </div>
            </th>
          );
        })}
      </tr>
    </Header>
  );
};

export default TableHeader;
