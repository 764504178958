import styled from "@emotion/styled";

export const Drawer = styled.div<{ open: boolean }>`
  width: ${({ open }) => (open ? "617px" : "0")};
  height: 100%;
  background: #f5f3f3;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 999;
  transition: width 0.4s ease;
  overflow: hidden;
  border-top-left-radius: 25px;
  padding: ${({ open }) => (open ? "30px 23px" : "0")};
  fill: #fcfcfc;
  stroke-width: 0.928px;
  stroke: #e6e6e8;
  filter: drop-shadow(0px 0.928px 4.638px rgba(0, 0, 0, 0.05));

  img.closeIcon {
    position: absolute;
    top: 10px;
    left: 10px;
    cursor: pointer;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-bottom: 10px;
  gap: 4px;
  font-size: 14px;
  font-weight: 600;
  line-height: 0;
  color: #231f20;
  width: 539px;

  .numberOfAlerts {
    text-transform: lowercase;
    width: 16px;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: #ef382f;
    font-size: 12px;
    color: #fff;
  }
`;

export const AlertsSection = styled.div`
  padding: 20px 13px;
  border-radius: 22.262px;
  border: 0.928px solid var(--Stroke-table, #e9e9ee);
  background: var(--Primary-Base-White, #fff);
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
  align-items: center;

  .alerts {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    overflow-y: auto;
  }
`;

export const Alert = styled.div`
  display: flex;
  width: 519px;
  padding: 10px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 14px;
  border: 1px solid var(--Text-Dark, #d5d5d5);
  background: var(--Primary-Base-White, #fff);

  .eventAlert {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
    width: 100%;

    &.recent {
      .alertTitle {
        box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.25);
        height: 55px;
        font-weight: 600;
      }
    }

    .alertTitle {
      border-radius: 10px;
      padding: 5px 10px;
      line-height: 0;
      display: flex;
      height: 45px;
      align-items: center;
      gap: 10px;
      align-self: stretch;
      font-size: 16px;
      font-weight: 400;

      &.alert {
        background: rgba(239, 56, 47, 0.1);

        .iconBlock {
          background: #e65f2b;
        }
      }

      &.resolve {
        background: rgba(0, 165, 155, 0.1);

        .iconBlock {
          background: #70a1e5;
        }
      }

      .iconBlock {
        width: 33px;
        height: 33px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #fff;
      }
    }

    .alertDescription {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-left: 20px;
    }
  }

  .breakLine {
    width: 504px;
    border: 0.928px solid #e6e6e8;
  }

  .buttons {
    display: flex;
    flex-direction: row;
    gap: 6px;
    align-self: stretch;

    button {
      display: flex;
      width: 100%;
      padding: 12px;
      justify-content: center;
      align-items: center;
      gap: 3.087px;
      align-self: stretch;
      border-radius: 13px;
      border: 0.617px solid #cdcdcd;
      background: #fafafa;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
      font-size: 12px;
      font-weight: 400;
      cursor: pointer;

      img {
        height: 11px;
      }

      &:hover {
        background: #f5f5f5;
      }

      &:active {
        background: #e9e9e9;
      }
    }
  }
`;
