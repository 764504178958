const LogoutRedirect = () => {
  const logoutUrl = localStorage.getItem("logoutUrl");
  // this seems to come back as a string sometimes
  if (logoutUrl && logoutUrl !== "undefined") {
    window.location.href = logoutUrl;
  } else {
    localStorage.clear();
    window.location.href = "/";
  }
  return null;
};

export default LogoutRedirect;
