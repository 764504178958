import { useTranslation } from "react-i18next";
import { useClearRefinements, useToggleRefinement } from "react-instantsearch";
import ArchivedIcon from "assets/icons/archived_icon.svg";

const AlgoliaArchivedButton = () => {
  const { t } = useTranslation();
  const { value, refine } = useToggleRefinement({ attribute: "archived" });
  const { refine: clear } = useClearRefinements({ includedAttributes: ["archived"] });

  return (
    <button
      className={`archivedButton ${value.isRefined && "active"}`}
      type="button"
      onClick={() => {
        if (value.isRefined) {
          clear();
        } else {
          refine({ isRefined: false });
        }
      }}
    >
      <img src={ArchivedIcon} alt="Archived" />
      <span>{t("Archived")}</span>
    </button>
  );
};

export default AlgoliaArchivedButton;
