import styled from "@emotion/styled";

export const SearchWrapper = styled.div`
  display: flex;
  padding: 10px;
  width: 100%;
  max-width: 771px;
  position: relative;

  img.clearSearch {
    position: absolute;
    right: 25px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    display: none;

    &.show {
      display: block;
    }
  }
`;

export const SearchInput = styled.input`
  width: 100%;
  padding: 10px 15px 10px 40px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 25px;
  outline: none;
  margin: 0 auto;
  cursor: pointer;

  &:focus {
    border-color: #007bff;
    box-shadow: 0 0 8px rgba(0, 123, 255, 0.5);
  }

  &:disabled {
    cursor: not-allowed;
  }
`;

export const Icon = styled.img`
  position: absolute;
  left: 28px;
  top: 50%;
  transform: translateY(-50%);
  color: #ccc;
  pointer-events: none;
`;
