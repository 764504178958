import styled from "@emotion/styled";

export const MoreDetailsContainer = styled.div<{ show: boolean }>`
  display: ${(props) => (props.show ? "flex" : "none")};
  width: 100%;
  padding: 33px 15px;
  gap: 24px;
  position: relative;
  background: #f5f3f3;
`;
