// Tooltip.tsx
import React from "react";
import { TooltipContainer, TooltipContent } from "./Tooltip.styled";

interface TooltipProps {
  content: React.ReactNode;
  position?: "top" | "bottom" | "left" | "right";
  enabled?: boolean;
  className?: string;
  children: React.ReactNode;
}

const Tooltip: React.FC<TooltipProps> = ({
  content,
  position = "top",
  enabled = true,
  className,
  children,
}) => {
  if (!enabled) return <>{children}</>;
  return (
    <TooltipContainer className={"tooltipContainer " + className} position={position}>
      {children}
      <TooltipContent className="tooltipContent" position={position}>
        {content}
      </TooltipContent>
    </TooltipContainer>
  );
};

export default Tooltip;
