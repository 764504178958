import { useState, useEffect } from "react";
import { useSearchBox } from "react-instantsearch";
import { useTranslation } from "react-i18next";
import SearchBar from "components/SearchBar";
import SearchIcon from "assets/icons/search_icon.svg";
import config from "config";

const { USE_BACKEND_RECORDS } = config.envs;

const DEBOUNCE_DELAY = 500;

const Search = () => {
  const { refine: refineSearchBox, query, clear } = useSearchBox();
  const [searchValue, setSearchValue] = useState(query);
  const { t } = useTranslation();

  useEffect(() => {
    if (searchValue.length === 1) return;
    const debounced = setTimeout(() => {
      refineSearchBox(searchValue);
    }, DEBOUNCE_DELAY);
    return () => clearTimeout(debounced);
  }, [searchValue, refineSearchBox]);

  useEffect(() => {
    setSearchValue(query);
  }, [query]);

  return (
    <SearchBar
      placeholder={t("What are you looking for?") as string}
      searchQuery={searchValue}
      onChange={(e) => setSearchValue(e.target.value)}
      icon={SearchIcon}
      clearFn={clear}
      disabled={USE_BACKEND_RECORDS === "true"}
    />
  );
};

export default Search;
