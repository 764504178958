import styled from "@emotion/styled";

export const Header = styled.thead`
  color: #2a2a2a;
  font-size: 15px;
  font-weight: 600;
  position: relative;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);

  th {
    padding: 10px 15px;
    z-index: 3;
    position: sticky;
    top: 0;
    background: #fff;
    border-bottom: 1px solid #e9e9ee;
    white-space: nowrap;
    text-align: left;

    &:first-of-type {
      z-index: 4;
      position: sticky;
      left: 0;
    }

    .headerContent {
      display: flex;
      align-items: center;
      flex-direction: row;
      gap: 10px;

      .headerText {
        display: flex;
        align-items: center;
        flex-direction: column;
      }
    }

    &.sortable {
      cursor: pointer;

      img.asc {
        transform: rotate(-90deg);
      }

      img.desc {
        transform: rotate(90deg);
      }
    }

    &.Reference {
      padding-left: 50px;
    }

    &.originalEta,
    &.currentEta {
      text-align: center;

      .headerText > span {
        display: block;

        &:first-of-type {
          font-size: 10px;
        }
      }
    }

    &.eta_delay {
      padding-right: 50px;
    }
  }
`;
