import { Fragment } from "react";
import { SectionContent, Separation } from "./SectionTable.styled";
import TrackingRow from "features/Tracking/components/TrackingRow";
import { TrackingRecord } from "types/trackingTypes";

const SectionTable = ({ records }: { records: TrackingRecord[] }) => {
  return (
    <>
      <SectionContent>
        {records.map((record, index) => (
          <Fragment key={index}>
            {index != 0 && <Separation />}
            <TrackingRow trackingRecord={record} />
          </Fragment>
        ))}
      </SectionContent>
    </>
  );
};

export default SectionTable;
