import { useEffect, useState } from "react";
import { downloadFile } from "services/api/files";

export const useFileURL = (fileId: string, accessToken: string) => {
  const [fileURL, setFileURL] = useState("");

  useEffect(() => {
    if (!fileId) return;
    const fetchFile = async () => {
      const data = await downloadFile(accessToken, fileId);
      const url = URL.createObjectURL(new Blob([data], { type: "application/pdf" }));
      setFileURL(url);
    };
    fetchFile();

    return () => {
      URL.revokeObjectURL(fileURL);
    };
  }, [accessToken, fileId]);

  return [fileURL, setFileURL] as const;
};
