import { useState } from "react";
import { TrackingRecord, AlertType } from "types/trackingTypes";
import { formatDate } from "utils/date";
import { capitalize } from "utils/string";
import { calculateDifferenceInDays } from "utils/date";
import { useTranslation } from "react-i18next";
import { useLegStore, useTripStore } from "store/trackingStore";
import { useActiveAlertsStore } from "store/activeAlertsStore";
import { modeOfTransportIcons } from "features/Tracking/constants/tableData";
import { statuses, statusTransportIcons } from "features/Tracking/constants/statuses";
import { alertsMessages } from "features/Tracking/constants/alerts";
import Tooltip from "components/ui/Tooltip";
import TruckIcon from "assets/icons/truck_icon.svg";
import ArrowRightIcon from "assets/icons/arrow_right_icon.svg";
import AlertIcon from "assets/icons/alert_icon.svg";
import ClipboardIcon from "assets/icons/clipboard_icon.svg";
import InvoiceIcon from "assets/icons/invoice_icon.svg";
import config from "config";

const { SHOW_TRIP_CARD } = config.ffs;

const ETADelayMarker = ({
  etaDelayInDays,
  endDate,
  t,
}: {
  etaDelayInDays: number | null;
  endDate?: Date | null;
  t: { (key: string): string };
}) => {
  if (etaDelayInDays === null) return null;
  if (endDate) return <p className="delayMarker gray">{t("Ended")}</p>;

  if (etaDelayInDays === 0) return <p className="delayMarker">{t("No Delay")}</p>;

  const delayTextFormat = etaDelayInDays === 1 ? `1 ${t("day")}` : `${etaDelayInDays} ${t("day")}s`;
  const classColor =
    etaDelayInDays > 5 && etaDelayInDays <= 10 ? "yellow" : etaDelayInDays > 10 ? "red" : "gray";

  return <p className={`delayMarker ${classColor}`}>{delayTextFormat}</p>;
};

const ShellLegMessageDoc = ({
  children,
  modeOfTransport,
  isShellLeg,
  t,
}: {
  children: React.ReactNode;
  modeOfTransport: "customs" | "ground" | "ocean";
  isShellLeg: boolean;
  t: { (key: string): string };
}) => {
  const docTypes = {
    customs: "Pedimento",
    ground: "Carta Porte",
    ocean: "Bill of Lading",
  } as const;

  return (
    <Tooltip
      content={
        <p>
          {t("We have not yet received the")} <strong>{docTypes[modeOfTransport]}</strong>{" "}
          {t("for this leg.")}
        </p>
      }
      position="right"
      enabled={isShellLeg}
      className="shellMessage"
    >
      {children}
    </Tooltip>
  );
};

const TrackingRow = ({ trackingRecord }: { trackingRecord: TrackingRecord }) => {
  const [showLegs, setShowLegs] = useState(false);
  const [isTripHovered, setIsTripHovered] = useState(false);
  const { legId, setLegId } = useLegStore();
  const { setActiveAlerts, setTripId, setEtaDelayDays } = useActiveAlertsStore();
  const { setTripId: setTripIdStore } = useTripStore();

  const {
    t,
    i18n: { language },
  } = useTranslation();

  const {
    originalEta: trackingOriginalETA,
    currentEta: trackingCurrentETA,
    purchaseOrderNumbers = [],
    invoiceNumbers = [],
    start: tripStartDate,
    end: tripEndDate,
  } = trackingRecord;
  const isTripWithAlerts = trackingRecord.alerts?.length > 0;
  const etaDelayInDays =
    trackingOriginalETA && trackingCurrentETA
      ? calculateDifferenceInDays(trackingOriginalETA, trackingCurrentETA)
      : null;

  const showTripCard = () => {
    setTripIdStore(trackingRecord.id);
    if (etaDelayInDays) setEtaDelayDays?.(etaDelayInDays);
  };

  return (
    <>
      <tr
        className={`parentTrip ${isTripWithAlerts && "tripWithAlerts"} ${isTripHovered && "active"}`}
        onMouseOver={() => setIsTripHovered(true)}
        onMouseLeave={() => setIsTripHovered(false)}
        onClick={showTripCard}
        data-testid={
          isTripWithAlerts ? `alert-row-${trackingRecord.id}` : `tracking-row-${trackingRecord.id}`
        }
      >
        <td className="vendor">
          <p className="trimText" title={trackingRecord.vendor}>
            {capitalize(trackingRecord.vendor)}
          </p>
        </td>
        <td>{tripStartDate ? formatDate(new Date(tripStartDate), language) : "-"}</td>
        <td>
          <strong>{trackingRecord.division || "-"}</strong>
        </td>
        <td>
          <strong>{trackingRecord.originCountryCode}</strong>
        </td>
        <td>
          <strong>{trackingRecord.destinationCountryCode}</strong>
        </td>
        <td className="cargo">
          <strong>{t("Cargo")}:</strong> {trackingRecord.cargoCount}
        </td>
        <td>
          <p className="trimText">{trackingRecord.currentLeg?.details?.carrier || "-"}</p>
        </td>
        <td>{trackingRecord.currentLeg?.details.modeOfTransport || "-"}</td>
        <td>{trackingOriginalETA ? formatDate(trackingOriginalETA, language) : "-"}</td>
        <td>{trackingCurrentETA ? formatDate(trackingCurrentETA, language) : "-"}</td>
        <td>
          <ETADelayMarker etaDelayInDays={etaDelayInDays} t={t} />
        </td>
        <td>{tripEndDate ? formatDate(new Date(tripEndDate), language) : "-"}</td>
        <td className="alertsColumn">
          {isTripWithAlerts && (
            <div
              className="alertsTrip"
              data-testid={`alert-icon-${trackingRecord.id}`}
              onClick={() => {
                setTripId(trackingRecord.id);
                setActiveAlerts(trackingRecord.alerts as AlertType[]);
                if (etaDelayInDays) setEtaDelayDays?.(etaDelayInDays);
              }}
            >
              <div className="alertsIndicator" data-testid={`alert-indicator-${trackingRecord.id}`}>
                <img src={AlertIcon} className="alertIcon" alt="Alert Icon" />
                <div className="alertCount" data-testid={`alert-count-${trackingRecord.id}`}>
                  {trackingRecord.alerts?.length}
                </div>
              </div>

              <div className="alertsMessage" data-testid={`alert-message-${trackingRecord.id}`}>
                <ul>
                  {trackingRecord.alerts?.map((alert: AlertType) => (
                    <li key={alert.issueId}>
                      {t(alertsMessages[alert.topic][alert.mostRecentEvent] as string)}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}
        </td>
      </tr>
      <tr
        className={`parentTrip tripMoreInfo ${isTripHovered ? "active" : ""}`}
        onMouseOver={() => setIsTripHovered(true)}
        onMouseLeave={() => setIsTripHovered(false)}
      >
        <td>
          <div className="expandLegs" onClick={() => setShowLegs(!showLegs)}>
            {t("Show More")}
            <img
              data-testid="leg-chevron"
              className={`arrowIcon ${showLegs && "open"}`}
              src={ArrowRightIcon}
              alt="arrowIcon"
              onClick={() => {
                setShowLegs(!showLegs);
              }}
            />
          </div>
        </td>
        <td className="info">
          <div>
            <img src={ClipboardIcon} alt="Clipboard Icon" />
            <p>
              <strong>PO:</strong>{" "}
              {purchaseOrderNumbers?.length ? purchaseOrderNumbers.join(" | ") : "-"}
            </p>
          </div>
          <div>
            <img src={InvoiceIcon} alt="Invoice Icon" />
            <p>
              <strong>{t("Invoices")}:</strong>{" "}
              {invoiceNumbers?.length ? invoiceNumbers.join(" | ") : "-"}
            </p>
          </div>
        </td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
      {showLegs &&
        trackingRecord.legs.map((leg, index) => {
          const legOrigin = leg.origin === "UNKNOWN" ? "TBD*" : capitalize(leg.origin);
          const legDestination =
            leg.destination === "UNKNOWN" ? "TBD*" : capitalize(leg.destination);
          const legCarrier = leg.carrier === "UNKNOWN" ? "TBD*" : capitalize(leg.carrier);
          const legModeOfTransport = capitalize(leg.modeOfTransport);
          const legCurrentEta = leg.currentEta || leg.transportCurrentEta;
          const legOriginalEta = leg.originalEta || leg.transportOriginalEta;
          const isShellLeg = leg.referenceId.includes("tbd");
          const isCustoms = leg.modeOfTransport === "customs";
          const referenceValue = isShellLeg ? `${leg.modeOfTransport}*` : leg.referenceId;
          const legStatus = leg.transportStatus ?? leg.status;
          const legStatusIcon = statusTransportIcons[leg.modeOfTransport][statuses[legStatus]];
          const legEtaDelayInDays =
            legOriginalEta && legCurrentEta
              ? calculateDifferenceInDays(legOriginalEta, legCurrentEta)
              : null;

          return (
            <tr
              data-testid={`leg-${leg.id}`}
              className={`legRow ${leg.id === legId ? "active" : ""} ${index === trackingRecord.legs.length - 1 ? "lastLegOfTrip" : ""}`}
              onClick={() => {
                if (SHOW_TRIP_CARD) {
                  showTripCard();
                  return;
                }
                setTripId(trackingRecord.id);
                setLegId(leg.id);
              }}
              key={leg.referenceId}
            >
              <td className="reference">
                <div className={`tripIconBlock ${leg.modeOfTransport}`}>
                  <img
                    src={modeOfTransportIcons[leg.modeOfTransport] ?? TruckIcon}
                    alt="Tracking Icon"
                  />
                </div>
                <div className="referenceValue">
                  {isShellLeg && <p className="notStarted">{t("Document Not Yet Received")}</p>}
                  <ShellLegMessageDoc
                    modeOfTransport={leg.modeOfTransport}
                    isShellLeg={isShellLeg}
                    t={t}
                  >
                    <p className="trimText" title={!isShellLeg ? referenceValue : ""}>
                      {referenceValue}
                    </p>
                  </ShellLegMessageDoc>
                </div>
              </td>
              <td>{leg.start ? formatDate(new Date(leg.start), language) : "-"}</td>
              <td>-</td>
              <td>
                <ShellLegMessageDoc
                  modeOfTransport={leg.modeOfTransport}
                  isShellLeg={isShellLeg}
                  t={t}
                >
                  <p className="trimText" title={!isShellLeg ? legOrigin : ""}>
                    {legOrigin}
                  </p>
                </ShellLegMessageDoc>
              </td>
              <td>
                <ShellLegMessageDoc
                  modeOfTransport={leg.modeOfTransport}
                  isShellLeg={isShellLeg}
                  t={t}
                >
                  <p className="trimText" title={!isShellLeg ? legDestination : ""}>
                    {legDestination}
                  </p>
                </ShellLegMessageDoc>
              </td>
              <td>
                <ShellLegMessageDoc
                  modeOfTransport={leg.modeOfTransport}
                  isShellLeg={isShellLeg && !isCustoms}
                  t={t}
                >
                  {t(legModeOfTransport) + (isShellLeg && !isCustoms ? "*" : "")}
                </ShellLegMessageDoc>
              </td>
              <td>
                <ShellLegMessageDoc
                  modeOfTransport={leg.modeOfTransport}
                  isShellLeg={isShellLeg}
                  t={t}
                >
                  <p className="trimText" title={!isShellLeg ? legCarrier : ""}>
                    {legCarrier}
                  </p>
                </ShellLegMessageDoc>
              </td>
              <td className="status">
                <div>
                  {legStatusIcon && <img src={legStatusIcon} alt={legStatus} />}
                  <p>{t(statuses[legStatus])}</p>
                </div>
              </td>
              <td>{legOriginalEta ? formatDate(legOriginalEta, language) : "-"}</td>
              <td>{legCurrentEta ? formatDate(legCurrentEta, language) : "-"}</td>
              <td>
                <ETADelayMarker etaDelayInDays={legEtaDelayInDays} endDate={leg.end} t={t} />
              </td>
              <td>{leg.end ? formatDate(new Date(leg.end), language) : "-"}</td>
              <td></td>
            </tr>
          );
        })}
    </>
  );
};

export default TrackingRow;
