import { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import { withRequiredAuthInfo, WithLoggedInAuthInfoProps } from "services/auth";
import { completeAuthorization, EmailProvider } from "../services/api/ingestion";
import { Container, Grid, Paper } from "@mui/material";
import Copyright from "../components/layouts/Copyright";

type CompleteEmailIngestionProps = WithLoggedInAuthInfoProps;

function CompleteEmailIngestion({ accessToken }: CompleteEmailIngestionProps) {
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState<string>();

  const { t } = useTranslation();
  const location = useLocation();
  const { provider } = useParams<{ provider: EmailProvider }>();
  const code = new URLSearchParams(location.search).get("code");

  const codeExchangeRef = useRef(false);

  useEffect(() => {
    (async () => {
      if (codeExchangeRef.current) return;
      codeExchangeRef.current = true;
      try {
        if (!code) {
          throw new Error();
        }
        await completeAuthorization(accessToken, provider, {
          code,
        });
        setSuccessMessage("Authorization for email ingestion succeeded!");
      } catch (_error) {
        setErrorMessage(
          "Oops! Looks like something went wrong. Please restart the authorization flow.",
        );
      }
    })();
    return () => {};
  }, []);

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12}>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              height: 600,
            }}
          >
            <div>
              <h2>{t(successMessage || errorMessage || "Processing...")}</h2>
            </div>
          </Paper>
        </Grid>
      </Grid>
      <Copyright />
    </Container>
  );
}

export default withRequiredAuthInfo(CompleteEmailIngestion);
