import { useEffect } from "react";
import { useActiveTenantStore } from "store/activeTenantStore";
import { OrgHelper, User } from "@propelauth/react";

export const useManageActiveTenant = (user: User | null, orgHelper: OrgHelper | null) => {
  const { setActiveTenant, activeTenant } = useActiveTenantStore();

  useEffect(() => {
    const activeUserEmail = localStorage.getItem("activeUserEmail");
    const storedTenant = localStorage.getItem("activeTenant");

    if (user?.email) {
      if (user.email !== activeUserEmail) {
        // New or different user has logged in
        localStorage.setItem("activeUserEmail", user.email);
      }
    } else {
      console.error("No user email");
    }

    const firstOrgName = orgHelper?.getOrgs()[0]?.orgName;
    if (firstOrgName) {
      // We want to use the org/tenant from the user that has logged in
      // Set the tenant to the the user's first organization name
      setActiveTenant(firstOrgName, false);
    } else {
      console.warn("No user tenant");
      // If the user that has logged in does not have org info, default to the last storedTenant
      if (storedTenant && storedTenant !== activeTenant) {
        console.warn("Tenant mismatch! Setting active tenant to local storage", {
          storedTenant,
          activeTenant,
        });
        setActiveTenant(storedTenant, false);
      }
    }
  }, [user, activeTenant, setActiveTenant, orgHelper]);
};
