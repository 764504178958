import { Container, Table } from "./TrackingTable.styled";
import TableHeader from "../TableHeader";
import SectionTable from "../SectionTable";
import TrackingDetails from "features/Tracking/components/TrackingDetails";
import { useLegStore } from "store/trackingStore";
import { TrackingRecord } from "types/trackingTypes";
import config from "config";
import AlertsSidebar from "../AlertsSidebar";
import AlgoliaInfiniteHits from "./components/AlgoliaInfiniteHits";
import TripCardModal from "features/TripCardModal";

const { SHOW_TRIP_CARD } = config.ffs;
const { USE_BACKEND_RECORDS } = config.envs;

type TrackingTableProps = {
  trackingRecords: TrackingRecord[];
};

const TrackingTable = ({ trackingRecords }: TrackingTableProps) => {
  const { legId } = useLegStore();

  return (
    <>
      <Container disabled={!!legId}>
        <Table>
          <TableHeader />
          <SectionTable records={trackingRecords} />
        </Table>
      </Container>
      {USE_BACKEND_RECORDS !== "true" && <AlgoliaInfiniteHits />}
      {SHOW_TRIP_CARD ? <TripCardModal /> : <TrackingDetails />}
      {!SHOW_TRIP_CARD && <AlertsSidebar />}
    </>
  );
};

export default TrackingTable;
