import styled from "@emotion/styled";

export const Button = styled.button`
  cursor: pointer;
  text-transform: capitalize;

  &.filterButton {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #eeeef1;
    border-radius: 13px;
    padding: 6px 10px 6px 5px;
    height: auto;

    &.limitWidth {
      max-width: 150px;
    }

    &.active {
      background: #f3f3f3;
      border: 1px solid #868686;
    }

    img.active {
      transform: rotate(45deg);
    }

    span {
      margin-left: 3px;
    }
  }
`;
