export type AlertMessage = {
  alert: string;
  resolve?: string;
};

export type AlertsMessages = {
  pedimentoPayment: AlertMessage;
  significantDelay: AlertMessage;
};

export const alertsMessages: AlertsMessages = {
  pedimentoPayment: {
    alert: "Pedimento has not been paid",
    resolve: "Pedimento has been paid",
  },
  significantDelay: {
    alert: "+10 Days Delay",
  },
} as const;

export const alertsTopicDisplay: Record<string, string> = {
  pedimentoPayment: "Pedimento Payment",
  significantDelay: "Significant Delay",
};
