import styled from "@emotion/styled";

export const Container = styled.div`
  display: flex;
  font-family: "DM Sans", sans-serif;
`;

export const Main = styled.main<{ collapsed: boolean }>`
  transition: width 0.5s ease;
  margin: 30px 25px 0 ${(props) => (props.collapsed ? "108px" : "221px")};
  width: 100%;
  overflow-x: ${(props) => (props.collapsed ? "hidden" : "auto")};
  overflow-y: hidden;

  h1 {
    margin: 0;
    font-size: 23px;
  }

  h3 {
    margin: 0;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  h4 {
    margin: 0;
    font-size: 13px;
  }
`;
