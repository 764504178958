import { create } from "zustand";
import { AlertType } from "types/trackingTypes";

type ActiveAlertsStore = {
  tripId: string;
  activeAlerts: AlertType[];
  etaDelayDays?: number;
  setTripId: (tripId: string) => void;
  setActiveAlerts: (activeAlerts: AlertType[]) => void;
  setEtaDelayDays?: (etaDelayDays: number) => void;
  clearActiveAlerts: () => void;
  removeAlert: (notificationId: string) => void;
};

export const useActiveAlertsStore = create<ActiveAlertsStore>((set) => ({
  tripId: "",
  activeAlerts: [],
  etaDelayDays: 0,
  setTripId: (tripId) => set({ tripId }),
  setActiveAlerts: (activeAlerts) => set({ activeAlerts }),
  setEtaDelayDays: (etaDelayDays) => set({ etaDelayDays }),
  clearActiveAlerts: () => set({ tripId: "", activeAlerts: [], etaDelayDays: 0 }),
  removeAlert: (notificationId) =>
    set((state) => ({
      activeAlerts: state.activeAlerts.filter((alert) => alert.notificationId !== notificationId),
    })),
}));
