import { Container } from "./FloatingIndicator.styled";
import CloseIcon from "assets/icons/close_icon.svg";

type FloatinIndicatorProps = {
  visible: boolean;
  onClose?: () => void;
  children: React.ReactNode;
};

const FloatingIndicator = ({ visible, onClose, children }: FloatinIndicatorProps) => {
  return (
    <Container visible={visible}>
      <button onClick={onClose}>
        <img src={CloseIcon} alt="Close" />
      </button>
      {children}
    </Container>
  );
};

export default FloatingIndicator;
