import { useQuery } from "@tanstack/react-query";
import { WithLoggedInAuthInfoProps, withAuthInfo } from "@propelauth/react";
import { isTokenExpired } from "utils/jwt";
import { getMetabaseToken } from "services/api/analytics";
import { useTranslation } from "react-i18next";

const fetchToken = async (accessToken: string, dashboardId: number, language: string) => {
  const token = await getMetabaseToken(accessToken, dashboardId);
  localStorage.setItem(language === "es" ? "metabaseSpanishToken" : "metabaseToken", token);
  return token;
};

type MetabaseDashboardProps = WithLoggedInAuthInfoProps & {
  dashboardId: number;
};

const MetabaseDashboard = withAuthInfo(({ accessToken, dashboardId }: MetabaseDashboardProps) => {
  const {
    i18n: { language },
  } = useTranslation();

  const getToken = async () => {
    let token = localStorage.getItem(language === "es" ? "metabaseSpanishToken" : "metabaseToken");
    if (!token || isTokenExpired(token)) {
      token = await fetchToken(accessToken, dashboardId, language);
    }
    return token;
  };

  const { data: metabaseToken } = useQuery({
    queryFn: getToken,
    queryKey: ["metabaseToken", dashboardId, language],
  });

  const url = metabaseToken
    ? `https://auba.metabaseapp.com/embed/dashboard/${metabaseToken}#bordered=false&titled=false`
    : "";

  return <iframe src={url} title="Metabase Dashboard" />;
});

export default MetabaseDashboard;
