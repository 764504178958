const EXCEL_REGEX = /\.(xls|xlsx)$/i;

export function capitalize(str: string): string {
  return str
    .split(" ")
    .map((word) => word[0].toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
}

export function splitAndCapitalize(str: string): string {
  const name = str
    .replace(/([A-Z])/g, " $1")
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
  return name;
}

export function hasExcelExtension(fileName: string): boolean {
  return EXCEL_REGEX.test(fileName);
}
