import React from "react";
import config from "config";
import {
  User,
  UserInformationForTesting,
  AuthProvider as AuthProviderReal,
  AuthProviderForTesting,
  OrgMemberInfo,
} from "@propelauth/react";

// do != false instead of == true so that "default" is true
const useAuth = config.envs.USE_AUTH !== "false";

const userInfo = {
  user: {
    userId: config.envs.MOCK_USER_ID,
    email: config.envs.MOCK_USER_EMAIL,
  } as User,
  orgMemberInfos: [
    {
      orgId: config.envs.MOCK_TENANT_ID,
      orgName: config.envs.MOCK_TENANT_NAME,
    } as OrgMemberInfo,
  ],
  accessToken: config.envs.MOCK_USER_ID,
} as UserInformationForTesting;

export const AuthProvider = ({ children }: { children?: React.ReactNode }) =>
  useAuth ? (
    <AuthProviderReal authUrl={config.envs.PROPELAUTH_AUTH_URL}>{children}</AuthProviderReal>
  ) : (
    <AuthProviderForTesting userInformation={userInfo}>{children}</AuthProviderForTesting>
  );

// Export pass-through to consolidate auth to this file
export {
  useRedirectFunctions,
  withAuthInfo,
  withRequiredAuthInfo,
  WithLoggedInAuthInfoProps,
  useLogoutFunction,
} from "@propelauth/react";
