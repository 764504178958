import apiClient from "./apiClient";
export type EmailProvider = "outlook" | "gmail";

interface RedirectUrlResponse {
  redirectUrl: string;
}

interface CompleteAuthorizationBody {
  code: string;
}

interface CompleteAuthorizationResponse {
  message: string;
}

export async function getIngestAuthorizationRedirectUrl(
  accessToken: string,
  provider: EmailProvider,
): Promise<RedirectUrlResponse> {
  if (provider === "outlook" || provider === "gmail") {
    const res = await apiClient.get(`/oauth/authorization/redirect`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params: {
        provider: provider,
      },
    });
    return res.data;
  }
  throw new Error(`Unsupported provider: ${provider}`);
}

export async function completeAuthorization(
  accessToken: string,
  provider: EmailProvider | undefined,
  body: CompleteAuthorizationBody,
): Promise<CompleteAuthorizationResponse> {
  if (!provider) {
    throw new Error("Provider is required");
  }
  const res = await apiClient.post(`/oauth/authorization/complete`, body, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    params: {
      provider: provider,
    },
  });
  return res.data;
}
