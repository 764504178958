import { useTranslation } from "react-i18next";
import { useActiveAlertsStore } from "store/activeAlertsStore";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { withAuthInfo, WithLoggedInAuthInfoProps } from "@propelauth/react";
import { updateNotification } from "services/api/alerts";
import { alertsMessages } from "features/Tracking/constants/alerts";
import { AlertType } from "types/trackingTypes";
import { AlertsContainer, Header, Alert } from "./AlertsSection.styled";
import DocumentsIcon from "assets/icons/documents_icon.svg";
import EyeSlashIcon from "assets/icons/eye_slash_icon.svg";
import CloseIcon from "assets/icons/close_icon.svg";
import AlarmClockIcon from "assets/icons/alarm_clock_icon.svg";
import { useTripStore } from "store/trackingStore";

type AlertsSectionProps = {
  alerts: AlertType[];
} & WithLoggedInAuthInfoProps;

const AlertsSection = ({ accessToken, alerts }: AlertsSectionProps) => {
  const { t } = useTranslation();
  const { etaDelayDays } = useActiveAlertsStore();
  const { tripId } = useTripStore();
  const queryClient = useQueryClient();

  const { mutateAsync: asyncUpdateNotification } = useMutation({
    mutationFn: ({
      notificationId,
      action,
    }: {
      notificationId: string;
      action: "dismiss" | "snooze";
    }) => updateNotification(accessToken, notificationId, action),
    onSuccess: () => {
      queryClient.invalidateQueries(["getTripDetails", accessToken, tripId]);
    },
  });

  return (
    <AlertsContainer data-testid="alert-sidebar">
      <Header>
        <span className="numberOfAlerts">{alerts.length}</span>
        <span>{t("Alerts")}</span>
      </Header>
      <div className="alerts">
        {alerts.map(({ issueId, notificationId, topic, mostRecentEvent }) => (
          <Alert key={issueId} data-testid={`alert-card-${issueId}`}>
            {topic === "pedimentoPayment" && mostRecentEvent === "resolve" ? (
              <>
                <div className="eventAlert recent">
                  <div className="alertTitle resolve">
                    <div className="iconBlock">
                      <img src={DocumentsIcon} alt="Documents Icon" />
                    </div>
                    <p>{t(alertsMessages["pedimentoPayment"]["resolve"] as string)}</p>
                  </div>
                  <p className="alertDescription">
                    {t("This Pedimento is now paid. No more action needed")}.
                  </p>
                </div>
                <hr className="breakLine" />
                <div className="eventAlert">
                  <div className="alertTitle alert">
                    <div className="iconBlock">
                      <img src={DocumentsIcon} alt="Documents Icon" />
                    </div>
                    <p>{t(alertsMessages["pedimentoPayment"]["alert"])}</p>
                  </div>
                  <p className="alertDescription">
                    {t(
                      "This Pedimento has not been paid according to SOIA Net and is due to arrive to the port of destination in less than two days",
                    )}
                    .
                  </p>
                </div>
              </>
            ) : (
              <div className="eventAlert recent">
                <div className="alertTitle alert">
                  <div className="iconBlock">
                    <img src={DocumentsIcon} alt="Documents Icon" />
                  </div>
                  <p>{t(alertsMessages[topic][mostRecentEvent] as string)}</p>
                </div>
                <p className="alertDescription">
                  {t("AlertETADelayDescription", { days: etaDelayDays })}
                </p>
              </div>
            )}

            <div className="buttons">
              {mostRecentEvent === "alert" ? (
                <>
                  <button
                    data-testid={`alert-snooze-${issueId}`}
                    onClick={() => {
                      asyncUpdateNotification({
                        notificationId: notificationId,
                        action: "snooze",
                      });
                    }}
                  >
                    <img src={AlarmClockIcon} alt="Alarm Clock Icon" />
                    {t("Snooze")}
                  </button>
                  <button
                    data-testid={`alert-dismiss-${issueId}`}
                    onClick={() => {
                      asyncUpdateNotification({
                        notificationId: notificationId,
                        action: "dismiss",
                      });
                    }}
                  >
                    <img src={EyeSlashIcon} alt="Eye Slash Icon" />
                    {t("Not interested")}
                  </button>
                </>
              ) : (
                <button
                  data-testid={`alert-dismiss-${issueId}`}
                  onClick={() => {
                    asyncUpdateNotification({
                      notificationId: notificationId,
                      action: "dismiss",
                    });
                  }}
                >
                  <img src={CloseIcon} alt="Close Icon" />
                  {t("Dismiss")}
                </button>
              )}
            </div>
          </Alert>
        ))}
      </div>
    </AlertsContainer>
  );
};

export default withAuthInfo(AlertsSection);
