import { create } from "zustand";

type LegStore = {
  legId: string | null;
  setLegId: (legId: string | null) => void;
};

export const useLegStore = create<LegStore>()((set) => ({
  legId: null,
  setLegId: (legId) => set({ legId }),
}));

type TripStore = {
  tripId: string | null;
  setTripId: (tripId: string | null) => void;
  clearTripId: () => void;
};

export const useTripStore = create<TripStore>((set) => ({
  tripId: null,
  setTripId: (tripId) => set({ tripId }),
  clearTripId: () => set({ tripId: null }),
}));
