import apiClient from "./apiClient";
import { SavedFilter } from "types/filterTypes";

export const getSavedFilter = async (accessToken: string): Promise<SavedFilter | null> => {
  const res = await apiClient.get("/search/filters", {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  if (!res?.data) {
    return null;
  }

  return res.data;
};

export const upsertFilter = async (
  accessToken: string,
  filterData: string,
): Promise<SavedFilter> => {
  const res = await apiClient.post(
    "/search/filters",
    { data: filterData },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );

  return res.data;
};
