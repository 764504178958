import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useActiveAlertsStore } from "store/activeAlertsStore";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateNotification } from "services/api/alerts";
import { Drawer, AlertsSection, Header, Alert } from "./AlertsSidebar.styled";
import { alertsMessages } from "features/Tracking/constants/alerts";
import AlarmClockIcon from "assets/icons/alarm_clock_icon.svg";
import EyeSlashIcon from "assets/icons/eye_slash_icon.svg";
import CloseIcon from "assets/icons/close_icon.svg";
import DocumentsIcon from "assets/icons/documents_icon.svg";
import CloseCircleIcon from "assets/icons/close_circle_icon.svg";
import { WithLoggedInAuthInfoProps, withAuthInfo } from "@propelauth/react";

const AlertsSidebar = ({ accessToken }: WithLoggedInAuthInfoProps) => {
  const drawerRef = useRef<HTMLDivElement>(null);
  const { activeAlerts, etaDelayDays, clearActiveAlerts, removeAlert } = useActiveAlertsStore();
  const { t } = useTranslation();
  const [isOpen, setiIsOpen] = useState(false);
  const queryClient = useQueryClient();

  const handleOutsideClick = (e: MouseEvent) => {
    if (drawerRef.current && !drawerRef.current.contains(e.target as Node) && isOpen) {
      clearActiveAlerts();
    }
  };

  const { mutateAsync: asyncUpdateNotification } = useMutation({
    mutationFn: ({
      notificationId,
      action,
    }: {
      notificationId: string;
      action: "dismiss" | "snooze";
    }) => updateNotification(accessToken, notificationId, action),
    onSuccess: (data, { notificationId }) => {
      queryClient.invalidateQueries(["getTrackingRecords", accessToken]);
      removeAlert(notificationId);
    },
  });

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [isOpen, setiIsOpen]);

  useEffect(() => {
    if (activeAlerts.length > 0) {
      setiIsOpen(true);
    } else {
      setiIsOpen(false);
    }
  }, [activeAlerts]);

  const sortedActivedAlerts = useMemo(
    () =>
      activeAlerts.sort(
        (a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime(),
      ),
    [activeAlerts],
  );

  return (
    <Drawer ref={drawerRef} open={isOpen}>
      <img
        data-testid="alert-sidebar-close-icon"
        className="closeIcon"
        src={CloseCircleIcon}
        alt="Close Circle Icon"
        onClick={clearActiveAlerts}
      />
      {isOpen && (
        <AlertsSection data-testid="alert-sidebar">
          <Header>
            <span className="numberOfAlerts">{activeAlerts.length}</span>
            <span>{t("Alerts")}</span>
          </Header>
          <div className="alerts">
            {sortedActivedAlerts.map(({ issueId, notificationId, topic, mostRecentEvent }) => (
              <Alert key={issueId} data-testid={`alert-card-${issueId}`}>
                {topic === "pedimentoPayment" && mostRecentEvent === "resolve" ? (
                  <>
                    <div className="eventAlert recent">
                      <div className="alertTitle resolve">
                        <div className="iconBlock">
                          <img src={DocumentsIcon} alt="Documents Icon" />
                        </div>
                        <p>{t(alertsMessages["pedimentoPayment"]["resolve"] as string)}</p>
                      </div>
                      <p className="alertDescription">
                        {t("This Pedimento is now paid. No more action needed")}.
                      </p>
                    </div>
                    <hr className="breakLine" />
                    <div className="eventAlert">
                      <div className="alertTitle alert">
                        <div className="iconBlock">
                          <img src={DocumentsIcon} alt="Documents Icon" />
                        </div>
                        <p>{t(alertsMessages["pedimentoPayment"]["alert"])}</p>
                      </div>
                      <p className="alertDescription">
                        {t(
                          "This Pedimento has not been paid according to SOIA Net and is due to arrive to the port of destination in less than two days",
                        )}
                        .
                      </p>
                    </div>
                  </>
                ) : (
                  <div className="eventAlert recent">
                    <div className="alertTitle alert">
                      <div className="iconBlock">
                        <img src={DocumentsIcon} alt="Documents Icon" />
                      </div>
                      <p>{t(alertsMessages[topic][mostRecentEvent] as string)}</p>
                    </div>
                    <p className="alertDescription">
                      {t("AlertETADelayDescription", { days: etaDelayDays })}
                    </p>
                  </div>
                )}

                <div className="buttons">
                  {mostRecentEvent === "alert" ? (
                    <>
                      <button
                        data-testid={`alert-snooze-${issueId}`}
                        onClick={() => {
                          asyncUpdateNotification({
                            notificationId: notificationId,
                            action: "snooze",
                          });
                        }}
                      >
                        <img src={AlarmClockIcon} alt="Alarm Clock Icon" />
                        {t("Snooze")}
                      </button>
                      <button
                        data-testid={`alert-dismiss-${issueId}`}
                        onClick={() => {
                          asyncUpdateNotification({
                            notificationId: notificationId,
                            action: "dismiss",
                          });
                        }}
                      >
                        <img src={EyeSlashIcon} alt="Eye Slash Icon" />
                        {t("Not interested")}
                      </button>
                    </>
                  ) : (
                    <button
                      data-testid={`alert-dismiss-${issueId}`}
                      onClick={() => {
                        asyncUpdateNotification({
                          notificationId: notificationId,
                          action: "dismiss",
                        });
                      }}
                    >
                      <img src={CloseIcon} alt="Close Icon" />
                      {t("Dismiss")}
                    </button>
                  )}
                </div>
              </Alert>
            ))}
          </div>
        </AlertsSection>
      )}
    </Drawer>
  );
};

export default withAuthInfo(AlertsSidebar);
