import styled from "@emotion/styled";

export const Drawer = styled.div<{ width: string }>`
  width: ${(props) => props.width};
  height: 100%;
  background: #f5f3f3;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 999;
  transition: width 0.4s ease;
  overflow: hidden;
  border-top-left-radius: 25px;
  display: flex;

  div.fileViewer {
    position: relative;
    width: 100%;

    iframe {
      margin-top: 33px;
      height: 680px;
      border: 10px solid #323639;
      border-top: 30px solid #323639;
      border-radius: 14px 0px 0px 14px;
      width: 100%;
      height: 90%;
    }

    button.close {
      position: absolute;
      top: 45px;
      left: 10px;
      background: transparent;
      cursor: pointer;
      border: none;
    }
  }
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 12px;
  left: 10px;
  background: transparent;
  border: none;
  cursor: pointer;
  outline: none;
`;

export const DrawerContent = styled.div`
  padding: 33px 15px;
  display: flex;
  gap: 24px;
  flex-direction: column;

  img.logo {
    width: 251px;
    margin-top: 30px;
    align-self: center;
  }

  .documents {
    height: 146px;
    font-size: 14px;

    table {
      margin-top: 10px;
      width: 100%;
      border-collapse: collapse;

      tr {
        cursor: pointer;

        &:not(:last-child) {
          border-bottom: 1px solid #ededf1;
        }

        &:hover {
          background: #f5f3f3;
        }

        &.active {
          background: #f5f3f3;
        }
      }

      td {
        padding: 3px;

        input {
          width: 12px;
        }

        div.fileName {
          display: flex;
          align-items: center;
          gap: 3px;
        }

        &.fileRefId {
          text-align: right;
          font-weight: 500;
        }
      }
    }
  }

  .leg {
    display: flex;
    flex-direction: column;

    h4 {
      margin-bottom: 7px;
      text-transform: capitalize;
    }

    .milestones {
      border-radius: 16.468px;
      background: #fffaf6;
      width: 448px;
      height: 103px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px 28px;
      font-size: 12px;

      .milestone {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 5px;
        font-size: 15px;
        color: #5a5a5a;
        font-weight: 500;

        p {
          margin: 0;
        }

        img {
          width: 32px;
          height: 32px;
        }
      }
    }
  }

  .lineItemsCard {
    display: flex;
    flex-direction: row;

    h4 {
      margin-bottom: 10px;
    }

    .containersList {
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      height: 212px;

      .containerItem {
        padding: 5px 10px;
        width: 123px;
        border-radius: 3.71px;
        font-size: 16px;
        font-weight: 500;
        cursor: pointer;

        &:hover {
          background-color: #ebfbfb;
        }

        &.active {
          background: #ebfbfb;
        }
      }
    }

    .contentContainer {
      width: 100%;

      .contentList {
        display: flex;
        flex-direction: column;
        background: #ebfbfb;
        border-radius: 0 16px 16px 0;
        height: 212px;
        overflow-y: auto;

        .contentItem {
          padding: 5px 10px;
        }
      }
    }
  }

  .leg,
  .documents,
  .lineItemsCard {
    width: 500px;
    padding: 17px 21px;
    border-radius: 25.739px;
    border: 1.072px solid #e9e9ee;
    background: #fff;
    box-shadow: 0px 1.07248px 5.36239px 0px rgba(0, 0, 0, 0.05);
  }
`;
