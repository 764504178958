import algoliasearch from "algoliasearch/lite";
import { useQuery } from "@tanstack/react-query";
import { InstantSearch } from "react-instantsearch";
import { getAlgoliaSearchAPIKey } from "services/api/search";
import { getSavedFilter } from "services/api/filters";
import { withAuthInfo, WithLoggedInAuthInfoProps } from "@propelauth/react";
import config from "config";

const { ALGOLIA_APP_ID, ALGOLIA_INDEX_NAME } = config.envs;

const AlgoliaSearchWrapper = withAuthInfo(
  ({
    accessToken,
    orgHelper,
    children,
  }: WithLoggedInAuthInfoProps & { children: React.ReactNode }) => {
    const isAdmin = orgHelper.getOrgByName("auba") !== undefined;

    const {
      data: savedQueryString,
      isLoading: savedQueryStringIsLoading,
      isError: savedQueryStringIsError,
    } = useQuery({
      queryFn: () => getSavedFilter(accessToken),
      queryKey: ["getSavedQueryString"],
      enabled: !isAdmin,
    });

    const {
      data: algoliasSearchAPIKey,
      isLoading: algoliasSearchAPIKeyIsLoading,
      isError: algoliasSearchAPIKeyIsError,
    } = useQuery({
      queryFn: () => getAlgoliaSearchAPIKey(accessToken),
      queryKey: ["algoliasSearchAPIKey"],
      enabled: !isAdmin,
    });

    if (
      algoliasSearchAPIKeyIsLoading ||
      algoliasSearchAPIKeyIsError ||
      savedQueryStringIsLoading ||
      savedQueryStringIsError ||
      isAdmin
    ) {
      return null;
    }

    const searchClient = algoliasearch(ALGOLIA_APP_ID, algoliasSearchAPIKey);

    return (
      <InstantSearch
        searchClient={searchClient}
        indexName={ALGOLIA_INDEX_NAME}
        routing={true}
        initialUiState={JSON.parse((savedQueryString?.data as string) || "{}")}
      >
        {children}
      </InstantSearch>
    );
  },
);

export default AlgoliaSearchWrapper;
