import { SearchWrapper, SearchInput, Icon } from "./SearchBar.styled";
import CloseIcon from "assets/icons/close_icon.svg";

interface SearchBarProps {
  placeholder?: string;
  onClick?: () => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  searchQuery: string;
  readOnly?: boolean;
  icon?: string;
  disabled?: boolean;
  clearFn?: () => void;
}

const SearchBar: React.FC<SearchBarProps> = ({
  placeholder,
  onClick,
  onChange = () => {},
  searchQuery,
  readOnly = false,
  icon,
  disabled = false,
  clearFn,
}: SearchBarProps) => {
  return (
    <SearchWrapper onClick={onClick}>
      {icon && <Icon src={icon} alt="Search icon" />}
      <SearchInput
        type="text"
        placeholder={placeholder}
        value={searchQuery}
        readOnly={readOnly}
        onChange={onChange}
        disabled={disabled}
      />
      {clearFn && (
        <img
          src={CloseIcon}
          alt="Close"
          onClick={clearFn}
          className={`${searchQuery ? "show" : ""} clearSearch`}
        />
      )}
    </SearchWrapper>
  );
};

export default SearchBar;
