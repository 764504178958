import { jwtDecode } from "jwt-decode";

/*
  This function checks if the token is expired or not.
  It returns true if the th

  @param {string} token: The token to check for expiration.
  @returns {boolean}: True if the token is not expired, otherwise false.

  IMPORTANT: This function is not safe to use to check if
  the token is valid since it doesn't check the signature.
*/
export const isTokenExpired = (token: string): boolean => {
  try {
    const { exp } = jwtDecode<{ exp: number }>(token);
    const currentTime = Math.floor(Date.now() / 1000);
    return exp < currentTime;
  } catch (error) {
    return true;
  }
};
