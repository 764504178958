// TooltipStyles.ts
import styled from "@emotion/styled";

type TooltipPosition = "top" | "bottom" | "left" | "right";

export const TooltipContainer = styled.div<{ position: TooltipPosition }>`
  display: flex;
  position: relative;
  cursor: pointer;
  width: max-content;
`;

export const TooltipContent = styled.div<{ position: TooltipPosition }>`
  position: absolute;
  width: max-content;
  z-index: 1;
  padding: 8px;
  color: #fff;
  background-color: #333;
  border-radius: 4px;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.2s;
  ${({ position }) => positionStyles[position]}

  .tooltipContainer:hover & {
    visibility: visible;
    opacity: 1;
  }

  &::before {
    content: "";
    position: absolute;
    border-style: solid;
    ${({ position }) => arrowStyles[position]}
  }
`;

const positionStyles: Record<TooltipPosition, string> = {
  top: "bottom: 100%; left: 50%; transform: translateX(-50%); margin-bottom: 5px;",
  bottom: "top: 100%; left: 50%; transform: translateX(-50%); margin-top: 5px;",
  left: "right: 100%; top: 50%; transform: translateY(-50%); margin-right: 5px;",
  right: "left: 100%; top: 50%; transform: translateY(-50%); margin-left: 5px;",
};

const arrowStyles: Record<TooltipPosition, string> = {
  top: `
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px 5px 0 5px;
    border-color: #333 transparent transparent transparent;
  `,
  bottom: `
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 0 5px 5px 5px;
    border-color: transparent transparent #333 transparent;
  `,
  left: `
    left: 100%;
    top: 50%;
    margin-top: -5px;
    border-width: 5px 0 5px 5px;
    border-color: transparent transparent transparent #333;
  `,
  right: `
    right: 100%;
    top: 50%;
    margin-top: -5px;
    border-width: 5px 5px 5px 0;
    border-color: transparent #333 transparent transparent;
  `,
};
