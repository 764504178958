import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRefinementList } from "react-instantsearch";
import FilterButton from "features/Tracking/components/FilterButton/FilterButton";
import { filterAttributes, filterItemsDisplay } from "features/Tracking/constants/filters";
import {
  ButtonWrapper,
  Dropdown,
  DropdownSection,
  FilterCategoryButton,
  FilterCategorySection,
} from "./FilterDropdown.styled";
import FilterIcon from "assets/icons/filter.svg";
import ArrowRight from "assets/icons/arrow_right_icon.svg";
import config from "config";

const { USE_BACKEND_RECORDS } = config.envs;

type FilterCategoryProps = {
  attribute: string;
  display: string;
};

const FilterCategory = ({ attribute, display }: FilterCategoryProps) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const { items, refine } = useRefinementList({ attribute, sortBy: [] });

  return (
    <>
      <FilterCategoryButton
        onClick={() => setOpen(!open)}
        className={"categoryButton " + (open ? "active" : "")}
      >
        {t(display)}
        <img src={ArrowRight} alt={open ? "Close" : "Open"} className={open ? "open" : ""} />
      </FilterCategoryButton>
      {open && (
        <FilterCategorySection>
          {items.length ? (
            items.map((item, i) => (
              <FilterButton
                key={item.label + i}
                text={filterItemsDisplay[item.label] || item.label}
                active={item.isRefined}
                onClick={() => {
                  refine(item.value);
                }}
              />
            ))
          ) : (
            <div>{t("No options available")}</div>
          )}
        </FilterCategorySection>
      )}
    </>
  );
};

const FilterDropdown = () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const tooltipRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (tooltipRef.current && !tooltipRef.current.contains(event.target as Node)) {
        setShowDropdown(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <ButtonWrapper ref={tooltipRef} className="wrapper">
      <button
        onClick={() => setShowDropdown(!showDropdown)}
        disabled={USE_BACKEND_RECORDS === "true"}
      >
        <img src={FilterIcon} alt="Filter" />
        <span>{t("Filter")}</span>
      </button>
      <Dropdown showDropdown={showDropdown}>
        <DropdownSection className="borderBot">
          <div className="sectionTitle">{t("Add Filter To Trips")}</div>
          <div className="sectionContent">
            {filterAttributes.trip.map(({ attribute, display }) => (
              <FilterCategory key={attribute} attribute={attribute} display={display} />
            ))}
          </div>
        </DropdownSection>

        <DropdownSection>
          <div className="sectionTitle">{t("Add Filter To Legs")}</div>
          <div className="sectionContent">
            {filterAttributes.leg.map(({ attribute, display }) => (
              <FilterCategory key={attribute} attribute={attribute} display={display} />
            ))}
          </div>
        </DropdownSection>
      </Dropdown>
    </ButtonWrapper>
  );
};

export default FilterDropdown;
