import apiClient from "./apiClient";
import { TrackingRecord, TrackingLeg, TrackingLegDetails, TripDetails } from "types/trackingTypes";
import config from "config";

export async function getTrackingRecords(
  accessToken: string,
  archived: boolean = false,
): Promise<TrackingRecord[]> {
  return _getRecords(accessToken, archived, "/tracking/records");
}

export async function getTrackingLegDetails(
  accessToken: string,
  legId: string | null,
): Promise<TrackingLegDetails | null> {
  if (!legId) return null;

  const res = await apiClient.get<{
    details: TrackingLeg & {
      transportStatusHistory: { [key: string]: string };
    };
    documents: {
      documentId: string;
      docType: string;
      referenceId: string;
      fileId: string;
      fileName: string;
    }[];
  }>(`/tracking/legs/${legId}/details`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  const sortedStatusHistory = Object.entries(res.data.details.transportStatusHistory)
    .map(([status, date]) => ({
      transportStatus: status,
      date: new Date(date),
    }))
    .sort((a, b) => a.date.getTime() - b.date.getTime());

  return {
    details: {
      ...res.data.details,
      transportStatusHistory: sortedStatusHistory,
      lastTrackingUpdate: new Date(res.data.details.lastTrackingUpdate),
      transportCurrentEta:
        res.data.details.transportCurrentEta && new Date(res.data.details.transportCurrentEta),
      transportOriginalEta:
        res.data.details.transportOriginalEta && new Date(res.data.details.transportOriginalEta),
      currentEta: res.data.details.currentEta && new Date(res.data.details.currentEta),
      originalEta: res.data.details.originalEta && new Date(res.data.details.originalEta),
    },
    documents: res.data.documents.map((doc) => ({
      ...doc,
    })),
  };
}

export async function getTripDetails(
  accessToken: string,
  tripId: string | null,
): Promise<TripDetails | null> {
  if (!tripId) return null;

  const res = await apiClient.get<TripDetails>(`/tracking/records/${tripId}/details`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  return { ...res.data };
}

export async function exportRecords(
  accessToken: string,
  archived: boolean = false,
  searchValue: string = "",
  refinementList = {},
) {
  const res = await apiClient.get<string>("/tracking/records/export", {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    params: {
      archived,
      searchValue,
      refinementList,
    },
    responseType: "blob",
  });
  return res.data;
}

// Function name and logic should be changed when different file types are supported
export async function uploadBLFiles(accessToken: string, files: FileList): Promise<void> {
  const formData = new FormData();
  for (let i = 0; i < files.length; i++) {
    formData.append("files", files[i]);
  }

  // Change the classification be dynamic based on the file type
  formData.append("classification", "Bill of Lading");

  const res = await apiClient.post("/tracking/files/bulk", formData, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "multipart/form-data",
    },
  });

  if (res.status !== 200) {
    throw new Error("Error uploading files");
  }

  return res.data;
}

async function _getRecords(
  accessToken: string,
  archived: boolean = false,
  path: string,
): Promise<TrackingRecord[]> {
  const res = await apiClient.get<{
    trips: TrackingRecord[];
  }>(`${config.envs.BACKEND_URL}${path}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    params: { archived },
  });

  return res.data.trips.map((record) => ({
    ...record,
    currentEta: record.currentEta && new Date(record.currentEta),
    originalEta: record.originalEta && new Date(record.originalEta),
    lastTrackingUpdate: new Date(record.lastTrackingUpdate),
    legs: record.legs.map((leg) => ({
      ...leg,
      lastTrackingUpdate: new Date(leg.lastTrackingUpdate),
      transportCurrentEta: leg.transportCurrentEta && new Date(leg.transportCurrentEta),
      transportOriginalEta: leg.transportOriginalEta && new Date(leg.transportOriginalEta),
      currentEta: leg.currentEta && new Date(leg.currentEta),
      originalEta: leg.originalEta && new Date(leg.originalEta),
    })),
  }));
}
