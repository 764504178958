import styled from "@emotion/styled";

export const ButtonWrapper = styled.div`
  position: relative;
  &.wrapper {
    flex-basis: auto;
  }

  button {
    padding: 12px 14px;
  }
`;

export const Dropdown = styled.div<{ showDropdown: boolean }>`
  position: absolute;
  z-index: 99;
  top: 43px;
  left: -135px;
  display: ${(props) => (props.showDropdown ? "block" : "none")};

  width: 325px;

  background: #ffffff;
  border: 0.5px solid #ededf1;
  box-shadow: 0px 2px 4px 0px #00000040;
  padding: 9px 13px 9px 13px;
  border-radius: 22px;

  font-size: 12px;
`;

export const DropdownSection = styled.div`
  &.borderBot {
    margin-bottom: 8px;
    border-bottom: 1.5px solid #ededf1;
  }

  .sectionTitle {
    margin-bottom: 8px;
  }

  .sectionContent {
    display: flex;
    flex-wrap: wrap;
  }

  button {
    margin-right: 12px;
    margin-bottom: 10px;
  }
`;

export const FilterCategoryButton = styled.button`
  &.categoryButton {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #eeeef1;
    border-radius: 13px;
    padding: 6px 10px 6px 5px;
    height: auto;

    &.active {
      background: #f3f3f3;
      border: 1px solid #868686;
    }

    img {
      height: 10px;
      width: 10px;
    }
  }
`;

export const FilterCategorySection = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 10px;
  border-bottom: 0.5px solid #ededf1;
`;
