import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

function Copyright() {
  return (
    <div>
      <Typography variant="body2" color="text.secondary" align="center" sx={{ marginTop: "16px" }}>
        {"Copyright © "}
        <Link color="inherit" href="https://auba.ai/">
          Auba, Inc.
        </Link>{" "}
        {new Date().getFullYear()}
        {"."}
      </Typography>
      <Typography variant="body2" color="text.secondary" align="center" sx={{ marginTop: "8px" }}>
        <Link color="inherit" href="/privacy-policy">
          Privacy Policy
        </Link>{" "}
      </Typography>
    </div>
  );
}

export default Copyright;
