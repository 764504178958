import { useRef } from "react";
import { useMutation } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import config from "config";
import { exportRecords, uploadBLFiles } from "services/api/tracking";
import { Container, Wrapper } from "./ActionButtons.styled";
import Search from "features/Tracking/components/Search";
import ExcelIcon from "assets/icons/excel_icon.svg";
import ExcelHoverIcon from "assets/icons/excel_hover_icon.svg";
import { withAuthInfo, WithLoggedInAuthInfoProps } from "services/auth";
import UploadIcon from "assets/icons/upload_icon.svg";
import FilterDropdown from "../FilterDropdown";
import ArchivedButton from "../AlgoliaArchivedButton";
import { useInstantSearch, useSearchBox, useToggleRefinement } from "react-instantsearch";

const { ALGOLIA_INDEX_NAME, USE_BACKEND_RECORDS } = config.envs;

const ActionButtons = ({ accessToken, setOpenLoadingIndicator }: ActionButtonsProps) => {
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const { query: searchValue } = useSearchBox();
  const { uiState } = useInstantSearch();
  const {
    value: { isRefined: isArchived },
  } = useToggleRefinement({ attribute: "archived" });

  const { mutateAsync: uploadBLFile } = useMutation({
    mutationFn: () => uploadBLFiles(accessToken, hiddenFileInput.current!.files!),
  });

  const { t } = useTranslation();

  const handleClickUploadFile = () => {
    hiddenFileInput.current?.click();
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      setOpenLoadingIndicator(true);
      uploadBLFile();
    }
  };

  const downloadRecords = async () => {
    const data = await exportRecords(
      accessToken,
      isArchived,
      searchValue,
      uiState[ALGOLIA_INDEX_NAME].refinementList,
    );
    const url = URL.createObjectURL(new Blob([data]));
    const link = document.createElement("a");
    const include = isArchived ? "Archived" : "Active";

    link.href = url;
    const stringDate = new Date().toISOString().split("T")[0];
    link.download = `Tracking_${include}_${stringDate}.xlsx`;
    document.body.appendChild(link);
    link.click();
    URL.revokeObjectURL(url);
    document.body.removeChild(link);
  };

  return (
    <Container data-testid="action-buttons">
      <h1>{t("Tracking")}</h1>
      <Wrapper>
        <Search />
        <FilterDropdown />
        <ArchivedButton />
        <button type="button" onClick={handleClickUploadFile}>
          <img src={UploadIcon} alt="Upload" />
          <span>{t("Upload")} BL</span>
        </button>
        <input
          type="file"
          id="file"
          style={{ display: "none" }}
          onChange={handleFileChange}
          ref={hiddenFileInput}
          accept=".pdf"
          multiple
        />
        <button
          type="button"
          className="excelButton"
          onClick={downloadRecords}
          disabled={USE_BACKEND_RECORDS === "true"}
        >
          <img src={ExcelIcon} alt="Excel icon" />
          <img src={ExcelHoverIcon} alt="Excel icon" className="hover" />
          <span>{t("Export")}</span>
        </button>
      </Wrapper>
    </Container>
  );
};

interface ActionButtonsProps extends WithLoggedInAuthInfoProps {
  setOpenLoadingIndicator: (value: boolean) => void;
}

export default withAuthInfo(ActionButtons);
