import ArrivedDesinationIcon from "assets/icons/arrived_destination_icon.svg";
import DischargedIcon from "assets/icons/discharged_icon.svg";
import LoadedVesselIcon from "assets/icons/loaded_vessel_icon.svg";
import ShipInTransitIcon from "assets/icons/ship_in_transit_icon.svg";
import ArrivalNoticeIcon from "assets/icons/arrival_notice_icon.svg";
import ToBeShippedIcon from "assets/icons/to_be_shipped_icon.svg";
import ShipIcon from "assets/icons/ship_icon.svg";
import CustomsIcon from "assets/icons/v2/customs_icon.svg";
import TruckIcon from "assets/icons/truck_icon.svg";

export const statusTranportIcons: { [key: string]: string } = {
  // TODO: this may need to be updated when we have more transport types
  "In Transit": ShipInTransitIcon,
  "Loaded On Vessel": LoadedVesselIcon,
  Discharged: DischargedIcon, // YES
  "Arrived in Destination": ArrivedDesinationIcon,
  "To Be Shipped": ToBeShippedIcon,
  "Arrival Notice": ArrivalNoticeIcon,
};

export const modeOfTransportIcons: Record<string, string> = {
  ocean: ShipIcon,
  ground: TruckIcon,
  customs: CustomsIcon,
};

export const tableHeaders = [
  { label: "Reference", sortable: false, sortKey: null },
  { label: "Start", sortable: false, sortKey: null },
  { label: "Division", sortable: false, sortKey: null },
  { label: "Origin", sortable: false, sortKey: null },
  { label: "Destination", sortable: false, sortKey: null },
  { label: "Mode", sortable: false, sortKey: null },
  { label: "Carrier", sortable: false, sortKey: null },
  { label: "Status", sortable: false, sortKey: null },
  {
    label: "Original ETA",
    sortable: true,
    sortKey: "originalEta",
    algoliaKey: "trip_original_eta",
  },
  { label: "Current ETA", sortable: true, sortKey: "currentEta", algoliaKey: "trip_current_eta" },
  { label: "ETA Delay", sortable: false, sortKey: null },
  { label: "End", sortable: false, sortKey: null },
  {
    label: "Alerts",
    sortable: true,
    sortKey: "alertNotificationCount",
    algoliaKey: "trip_alert_notification_count",
  },
];
