import styled from "@emotion/styled";

export const CargoUnitDetailsContainer = styled.div`
  width: 100%;

  .label {
    font-size: 14px;
    font-weight: 600;
    padding: 12px;
  }
  .header {
    padding: 10px 10px;
    background: #ebecf0;
    border-radius: 25.739px 25.739px 0px 0px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    img {
      padding-right: 5px;
    }
  }
  .lineItems {
    overflow: auto;
    background: #fff;
    height: 301px;
    border-radius: 25.739px;
    border: 1.072px solid #e9e9ee;
    box-shadow: 0px 1.07248px 5.36239px 0px rgba(0, 0, 0, 0.05);
  }
  .lineItem {
    padding: 16px 19px;
  }
  .modeOfTransport {
    height: 120px;
    width: 120px;
    padding: 0px 10px;
    border-radius: 25.739px;
    border: 1.072px solid #e9e9ee;
    background: #fff;
    box-shadow: 0px 1.07248px 5.36239px 0px rgba(0, 0, 0, 0.05);
    display: flex;
    align-items: center;
    img {
      padding: 0px 10px;
    }
  }
`;

export const Milestones = styled.div`
  padding: 10px;
  border-radius: 25.739px;
  border: 1.072px solid #e9e9ee;
  background: #fff;
  box-shadow: 0px 1.07248px 5.36239px 0px rgba(0, 0, 0, 0.05);
  height: 120px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;

  .row {
    display: flex;
    overflow: auto;
    width: 100%;
    justify-content: center;
  }
  .column {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .img {
      padding: 15px;
    }
  }
  .ata {
    width: 100%;
    color: rgba(0, 0, 0, 0.8);
    font-weight: 600;
    .item.mileStoneStatus:after {
      border-bottom-color: #4cdbca;
    }
  }
  .eta {
    width: 100%;
    color: #868686;
  }
  .item {
    text-align: center;
  }

  .milestone {
    img {
      position: relative;
      z-index: 2;
    }

    .mileStoneStatus {
      position: relative;

      &:after {
        position: absolute;
        content: "";
        border-bottom: 6px solid #cdcdcd;
        width: 100%;
        top: 8px;
        left: 50%;
        z-index: 1;
      }
    }

    &:last-child {
      .mileStoneStatus:after {
        display: none;
      }
    }
  }
`;
