import { useTranslation } from "react-i18next";
import { Button } from "./FilterButton.styled";
import AddCircle from "assets/icons/add_circle.svg";

interface FilterButtonProps {
  text: string;
  active: boolean;
  onClick: () => void;
  limitWidth?: boolean;
}

export default function FilterButton({
  text,
  active,
  onClick,
  limitWidth = true,
}: FilterButtonProps) {
  const { t } = useTranslation();

  return (
    <Button
      type="button"
      className={`filterButton ${active ? "active" : ""} 
        ${limitWidth ? "limitWidth" : ""}
        `}
      onClick={() => onClick()}
    >
      <img src={AddCircle} alt={active ? "Add" : "Remove"} className={active ? "active" : ""} />
      <span>{t(text)}</span>
    </Button>
  );
}
