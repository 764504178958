interface EnvConfig {
  AMPLITUDE_API_KEY: string;
  BACKEND_URL: string;
  AMPLITUDE_DISABLE_FOR_INTERNAL_USERS: string;
  MIXPANEL_TOKEN: string;
  MOCK_USER_ID: string;
  MOCK_USER_EMAIL: string;
  MOCK_TENANT_ID: string;
  MOCK_TENANT_NAME: string;
  ALGOLIA_APP_ID: string;
  ALGOLIA_INDEX_NAME: string;
  USE_ALGOLIA_AUTH: string;
  ALGOLIA_LOCAL_SEARCH_API_KEY: string;
  USE_AUTH: string;
  PROPELAUTH_AUTH_URL: string;
  METABASE_DASHBOARD_ID: string;
  METABASE_SPANISH_DASHBOARD_ID: string;
  USE_BACKEND_RECORDS: string;
}

type EnvKey = keyof EnvConfig;

// "Create React App" only reads env vars prepended with "REACT_APP_"
// https://create-react-app.dev/docs/adding-custom-environment-variables
function getEnv(processEnv: NodeJS.ProcessEnv, envKey: EnvKey, defaults: EnvConfig): string {
  return processEnv[`REACT_APP_${envKey}`] || defaults[envKey];
}

const NODE_ENV = process.env.NODE_ENV;
// "Create React App" has 3 node envs: "development", "test", "production"
enum ENV {
  DEV = "development",
  TEST = "test",
  PROD = "production",
}

/*****************
 * Define Config Vars
 * ***************/

const DEFAULT_CONFIGS: EnvConfig = {
  AMPLITUDE_API_KEY: "",
  BACKEND_URL: "",
  AMPLITUDE_DISABLE_FOR_INTERNAL_USERS: "",
  MIXPANEL_TOKEN: "",
  MOCK_USER_ID: "0b7710cb-cd9c-460e-aa8e-781406fe8b93",
  MOCK_USER_EMAIL: "mockemail@auba.ai",
  MOCK_TENANT_ID: "eaa52433-cc11-4770-b5bf-a1059297723c",
  MOCK_TENANT_NAME: "skywalker",
  ALGOLIA_APP_ID: "K8V569QUI8",
  ALGOLIA_INDEX_NAME: "trip",
  USE_ALGOLIA_AUTH: "true",
  ALGOLIA_LOCAL_SEARCH_API_KEY: "",
  USE_AUTH: "true",
  PROPELAUTH_AUTH_URL: "",
  METABASE_DASHBOARD_ID: "133",
  METABASE_SPANISH_DASHBOARD_ID: "133",
  USE_BACKEND_RECORDS: "false",
};

/*****************
 * Define Feature Flags
 * ***************/

const FEATURE_FLAGS: Record<string, boolean> = {
  SHOW_TRIP_CARD: false,
};

/*****************
 * Modify Vars Based On Env
 * ***************/

if (NODE_ENV === ENV.DEV) {
  FEATURE_FLAGS.SHOW_TRIP_CARD = true;
} else if (NODE_ENV === ENV.TEST) {
  DEFAULT_CONFIGS.USE_AUTH = "false";
  FEATURE_FLAGS.SHOW_TRIP_CARD = true;
} else if (NODE_ENV === ENV.PROD) {
  FEATURE_FLAGS.SHOW_TRIP_CARD = true;
}

/*****************
 * Set Env Configs
 * ***************/

/**
 * Specializes defaults to a target environment, if one is set.
 *
 * The target environment is expected to be set via REACT_APP_TARGET_ENVIRONMENT and
 * the following environments are recognized:
 * - sandbox
 * - prod
 * @param processEnv - Environment variables (typically `process.env`)
 * @param defaults - Existing set of defaults.
 */
function getTargetEnvDefaults(processEnv: NodeJS.ProcessEnv, defaults: EnvConfig): EnvConfig {
  const targetEnvironment = processEnv.REACT_APP_TARGET_ENVIRONMENT;
  if (targetEnvironment === "sandbox") {
    return {
      ...defaults,
      ALGOLIA_APP_ID: "7FG8VRVA8Q",
      ALGOLIA_INDEX_NAME: "trip",
      AMPLITUDE_API_KEY: "67774c3faf48dd082d552b5af866d31d",
      AMPLITUDE_DISABLE_FOR_INTERNAL_USERS: "true",
      PROPELAUTH_AUTH_URL: "https://auth.sandbox.auba.ai",
    };
  }

  if (targetEnvironment === "prod") {
    return {
      ...defaults,
      ALGOLIA_APP_ID: "K8V569QUI8",
      ALGOLIA_INDEX_NAME: "trip",
      AMPLITUDE_API_KEY: "ed0e76957b1813ef47e035a524c6e8cb",
      AMPLITUDE_DISABLE_FOR_INTERNAL_USERS: "true",
      METABASE_DASHBOARD_ID: "100",
      METABASE_SPANISH_DASHBOARD_ID: "166",
      PROPELAUTH_AUTH_URL: "https://auth.auba.ai",
    };
  }

  return { ...defaults };
}

function getEnvConfig(processEnv: NodeJS.ProcessEnv, defaults: EnvConfig): EnvConfig {
  const targetEnvDefaults = getTargetEnvDefaults(processEnv, defaults);

  const getValue = (envKey: EnvKey): string => {
    return getEnv(processEnv, envKey, targetEnvDefaults);
  };

  return {
    AMPLITUDE_API_KEY: getValue("AMPLITUDE_API_KEY"),
    BACKEND_URL: getValue("BACKEND_URL"),
    AMPLITUDE_DISABLE_FOR_INTERNAL_USERS: getValue("AMPLITUDE_DISABLE_FOR_INTERNAL_USERS"),
    MIXPANEL_TOKEN: getValue("MIXPANEL_TOKEN"),
    MOCK_USER_ID: getValue("MOCK_USER_ID"),
    MOCK_USER_EMAIL: getValue("MOCK_USER_EMAIL"),
    MOCK_TENANT_ID: getValue("MOCK_TENANT_ID"),
    MOCK_TENANT_NAME: getValue("MOCK_TENANT_NAME"),
    ALGOLIA_APP_ID: getValue("ALGOLIA_APP_ID"),
    ALGOLIA_INDEX_NAME: getValue("ALGOLIA_INDEX_NAME"),
    USE_ALGOLIA_AUTH: getValue("USE_ALGOLIA_AUTH"),
    ALGOLIA_LOCAL_SEARCH_API_KEY: getValue("ALGOLIA_LOCAL_SEARCH_API_KEY"),
    USE_AUTH: getValue("USE_AUTH"),
    PROPELAUTH_AUTH_URL: getValue("PROPELAUTH_AUTH_URL"),
    METABASE_DASHBOARD_ID: getValue("METABASE_DASHBOARD_ID"),
    METABASE_SPANISH_DASHBOARD_ID: getValue("METABASE_SPANISH_DASHBOARD_ID"),
    USE_BACKEND_RECORDS: getValue("USE_BACKEND_RECORDS"),
  };
}

const envConfig: EnvConfig = getEnvConfig(process.env, DEFAULT_CONFIGS);

/*****************
 * Set Overall Config
 * ***************/

interface Config {
  envs: EnvConfig;
  ffs: Record<string, boolean>;
}

const config: Config = {
  envs: envConfig,
  ffs: FEATURE_FLAGS,
};

export default Object.freeze(config);
