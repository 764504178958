import { useEffect } from "react";
import config from "config";
import * as amplitude from "@amplitude/analytics-browser";
import { Navigate, Route, Routes } from "react-router-dom";
import { withAuthInfo } from "services/auth";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import "assets/stylesheets/App.css";
import AlgoliaSearchWrapper from "features/Tracking/components/wrappers/AlgoliaSearchWrapper";
import LandingPage from "pages/LandingPage";
import PrivacyPolicy from "pages/PrivacyPolicy";
import Tracking from "pages/Tracking";
import EmailIngestion from "pages/EmailIngestion";
import Layout from "components/layouts/Layout";
import paths from "constants/paths";
import CompleteEmailIngestion from "pages/CompleteEmailIngestion";
import Insights from "pages/Insights";
import LogoutRedirect from "pages/LogoutRedirect";

const App = withAuthInfo(({ isLoggedIn, user }) => {
  useEffect(() => {
    if (
      config.envs.AMPLITUDE_DISABLE_FOR_INTERNAL_USERS === "true" &&
      user &&
      /auba\.ai$/.test(user?.email)
    ) {
      console.log("[amplitude]", "Disabling for internal user");
      return;
    }

    config.envs.AMPLITUDE_API_KEY &&
      amplitude.init(config.envs.AMPLITUDE_API_KEY, {
        userId: user?.userId || "",
        defaultTracking: true,
      });
  }, [user]);

  return (
    // TODO: Move QueryClientProvider to index.tsx when new UI is ready
    <QueryClientProvider client={new QueryClient()}>
      <Routes>
        {isLoggedIn ? (
          <>
            <Route path={paths.privacyPolicy} element={<PrivacyPolicy />} />
            <Route path={paths.tracking} element={<Layout />}>
              <Route
                index
                element={
                  <AlgoliaSearchWrapper>
                    <Tracking />
                  </AlgoliaSearchWrapper>
                }
              />
              <Route
                path={paths.emailIngestion}
                element={<EmailIngestion choices={[{ name: "Outlook" }, { name: "Gmail" }]} />}
              />
              <Route
                path="/ingestion/complete-authorization/:provider"
                element={<CompleteEmailIngestion />}
              />
              <Route path={paths.insights} element={<Insights />} />
              {/* ADD HERE MORE PROTECTED ROUTES */}
            </Route>
            {/* TODO: handle this more gracefully? */}
            <Route path="*" element={<Navigate to={paths.tracking} />} />
          </>
        ) : (
          <>
            <Route path={paths.privacyPolicy} element={<PrivacyPolicy />} />
            {/* We have configured Propel Auth to redirect users here after logout */}
            <Route path="/logout-redirect" element={<LogoutRedirect />} />
            <Route path="*" element={<LandingPage />} />
          </>
        )}
      </Routes>
    </QueryClientProvider>
  );
});

export default App;
