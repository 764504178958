import apiClient from "./apiClient";

export async function downloadFile(accessToken: string, fileId: string) {
  const res = await apiClient.get(`/files/${fileId}/download`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    responseType: "blob",
  });
  return res.data;
}
