import styled from "@emotion/styled";

export const Container = styled.div`
  margin-top: 20px;

  h1 {
    margin-bottom: 20px;
  }

  iframe {
    border: none;
    width: 100%;
    height: 85vh;
  }
`;
