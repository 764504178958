import styled from "@emotion/styled";

export const SectionContent = styled.tbody`
  display: table-row-group;
  transition: all 0.3s ease-in-out;
  color: #2a2a2a;
  font-weight: 400;
  text-transform: capitalize;

  tr {
    border-bottom: 1px solid #e9e9ee;
    border-left: 1px solid #e9e9ee;
    border-right: 1px solid #e9e9ee;
    z-index: 2;

    &.legRow {
      cursor: pointer;

      &.active {
        background: #f7f3ff;

        td {
          background: #f7f3ff;
        }
      }

      &.lastLegOfTrip {
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
      }
    }

    &:last-of-type {
      border-bottom: none;
    }

    &:first-of-type {
      .alertsMessage {
        top: 38px;
        border-radius: 0 0 10px 10px !important;
      }
    }

    &.parentTrip {
      background: #f3f3f3;
      position: relative;
      cursor: pointer;

      img.arrowIcon {
        cursor: pointer;
        transition: all 0.2s ease-in-out;

        &.open {
          transform: rotate(90deg);
        }
      }

      &.active {
        background: #f7f3ff;

        td:first-of-type {
          background: #f7f3ff;
        }
      }

      td:first-of-type {
        background: #f3f3f3;
      }
    }

    &.tripMoreInfo {
      position: relative;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
      cursor: pointer;
      background: #fff;
      z-index: 0;

      td:first-of-type {
        position: sticky;
        height: 50px;
        padding-left: 15px;
        background: #fff;
        z-index: 1;
      }

      &.active {
        background: #f7f3ff;

        td:first-of-type {
          background: #f7f3ff;
        }
      }

      .expandLegs {
        align-items: center;
        display: flex;
        gap: 10px;
        width: fit-content;
      }

      td.info {
        display: flex;
        gap: 10px;
        position: absolute;
        height: 50px;

        div {
          display: flex;
          align-items: center;
          gap: 5px;
        }
      }
    }

    &.tripWithAlerts {
      background: #fdebea;

      td:first-of-type {
        background: #fdebea;
      }
    }
  }

  td {
    padding: 0 15px;
    text-align: left;
    white-space: nowrap;
    text-overflow: ellipsis;

    p {
      margin: 0;

      &.delayMarker {
        padding: 3px 6px;
        width: 60px;
        text-align: center;
        border-radius: 2px;
        white-space: normal;
        border: 2px solid #4cdbca;
        font-weight: 700;

        &.yellow {
          border-color: #ffe03b;
        }

        &.gray {
          border-color: #e1e1e1;
        }

        &.red {
          background: #ef382f;
          color: #fff;
          border: none;
        }
      }
    }

    &:first-of-type {
      padding: 0;
      position: sticky;
      left: 0;
      background: #fff;
    }

    &.reference {
      display: flex;
      align-items: center;
      flex-direction: row;
      padding-right: 15px;
      height: 50px;

      .tripIconBlock {
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(255, 232, 208, 0.3);

        > img {
          width: 25px;
          height: 25px;
        }

        &.tripSummary {
          background: #f3f3f3;
        }

        &.ocean {
          background: rgba(209, 239, 237, 0.3);
        }

        &.customs {
          background: #f2ebf7;
        }

        &.parent {
          background: none;
          border-right: 1px solid #d0d0d0;
        }
      }

      .referenceValue {
        .notStarted {
          font-size: 10px;
          font-weight: 300;
          font-style: italic;
        }
      }

      p {
        font-weight: 600;
        margin: 0 10px;
        text-align: left;
      }

      img.arrowIcon {
        flex-shrink: 0;
      }
    }

    &.vendor {
      display: flex;
      align-items: center;
      height: 50px;

      p {
        padding-left: 15px;
      }
    }

    &.status {
      div {
        display: flex;
        align-items: center;
        gap: 5px;

        img {
          width: 20px;
          height: 20px;
        }
      }
    }

    &.cargo {
      img {
        margin-left: 6px;
      }
    }

    &.alertsColumn {
      .alertsTrip {
        .alertsIndicator {
          display: flex;
          cursor: pointer;
          position: relative;

          .alertCount {
            font-size: 12px;
            font-weight: 800;
            color: #fff;
            width: 15px;
            height: 15px;
            position: absolute;
            top: -4px;
            left: 12px;
            display: flex;
            padding: 2px;
            justify-content: center;
            align-items: center;
            border-radius: 47px;
            background: #ef392f;
            backdrop-filter: blur(1px);
          }

          &.alertIcon {
            width: 32px;
            height: 32px;
          }

          &:hover + .alertsMessage {
            display: block;
          }
        }

        .alertsMessage {
          position: absolute;
          bottom: 38px;
          right: 0;
          background: #fff;
          color: #2a2a2a;
          padding: 5px 10px;
          border-radius: 10px 10px 0 0;
          display: none;
          border: 1px solid var(--alerty, #ef382f);
          text-transform: none;
          height: fit-content;

          ul {
            padding: 5px 10px;
            margin: 0;

            li {
              font-size: 12px;
              font-weight: 700;
              text-align: left;
            }
          }
        }
      }
    }

    .trimText {
      @media (max-width: 1920px) {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 155px;
        cursor: default;
      }
    }

    .shellMessage {
      .tooltipContent {
        background: #f3f3f3;
        color: #2a2a2a;
        border-radius: 10.203px;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
        text-transform: none;
      }
    }
  }
`;

export const Separation = styled.tr`
  height: 10px;
  background: none;
  border-left: 0;
  border-right: 0;
  border-bottom: 0;
`;
